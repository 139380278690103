import * as api from '@/api';

import { isVersionUpdateAvailable } from './utils';

export const GET_DEVICES_REQUEST = 'devices/GET_DEVICES_REQUEST';
export const GET_DEVICES_RESPONSE = 'devices/GET_DEVICES_RESPONSE';
export const GET_DEVICES_FAILED = 'devices/GET_DEVICES_FAILED';

export const getDevices = systemId => async dispatch => {
  dispatch({ type: GET_DEVICES_REQUEST });

  try {
    const response = await api.getDevices(systemId);

    if (response.status === 200) {
      let devices = response.data.map(d => ({
        ...d,
        outdatedFirmware: isVersionUpdateAvailable(d),
      }));
      devices = devices.map(d => {
        if (d.slaves?.length) {
          d.slaves = d.slaves.map(s => ({
            ...s,
            outdatedFirmware: isVersionUpdateAvailable(s),
            gatewayDeviceId: d.id,
          }));
        }
        return d;
      });
      dispatch({
        type: GET_DEVICES_RESPONSE,
        devices,
      });
      return devices;
    }
    dispatch({ type: GET_DEVICES_FAILED });
  } catch {
    // TODO: Fire error notification and log error
    dispatch({ type: GET_DEVICES_FAILED });
  }

  return [];
};

export const SET_DEVICE_UPDATE_AVAILABLE = 'devices/SET_DEVICE_UPDATE_AVAILABLE';
export const setDeviceUpdateAvailable = (status = false) => ({ type: SET_DEVICE_UPDATE_AVAILABLE, status });

export const SET_SELECTED_DEVICE = 'devices/SET_SELECTED_DEVICE';
export const setSelectedDevice = device => dispatch => {
  dispatch({ type: SET_SELECTED_DEVICE, device });
};

export const SET_DEVICE_FIRMWARE_LOADING_STATUS = 'devices/SET_DEVICE_FIRMWARE_LOADING_STATUS';
export const GET_DEVICE_FIRMWARE_UPDATE_STATUS_RESPONSE = 'devices/GET_DEVICE_FIRMWARE_UPDATE_STATUS_RESPONSE';
export const GET_DEVICE_FIRMWARE_UPDATE_STATUS_FAILED = 'devices/GET_DEVICE_FIRMWARE_UPDATE_STATUS_FAILED';

export const getDeviceUpdateStatus =
  ({ deviceId, update = false }) =>
  async dispatch => {
    try {
      if (update) dispatch({ type: SET_DEVICE_FIRMWARE_LOADING_STATUS, statusRefreshing: true });

      const response = await api.getDeviceUpdateStatus(deviceId);
      if (response.status === 200) {
        const updateInfo = response.data;
        dispatch({ type: GET_DEVICE_FIRMWARE_UPDATE_STATUS_RESPONSE, updateInfo });
        return updateInfo;
      }
      dispatch({ type: GET_DEVICE_FIRMWARE_UPDATE_STATUS_FAILED });
      return null;
    } catch {
      dispatch({ type: GET_DEVICE_FIRMWARE_UPDATE_STATUS_FAILED });
      return null;
    }
  };

export const CHANGE_SELECTED_TAB = 'systemSettings/CHANGE_SELECTED_TAB';
export const changeSelectedTab = tab => dispatch => {
  dispatch({ type: CHANGE_SELECTED_TAB, selectedTab: tab });
};

export const HIDE_CONFIRM_ON_DEVICE = 'devices/HIDE_CONFIRM_ON_DEVICE';
export const hideConfirmOnDevice = () => dispatch => {
  dispatch({ type: HIDE_CONFIRM_ON_DEVICE });
};
