import {
  GET_ACTIVE_TAB,
  GET_REPORTS_BY_PARAMERETS_REQUEST,
  GET_REPORTS_RESPONSE,
  GET_REPORT_BY_PARAMERETS_RESPONSE,
  WEEK_INDEX,
  weeklyTab,
} from './actions';

const initialState = {
  reports: {},
  selectedReport: {},
  activeTab: weeklyTab,
  activeIndex: WEEK_INDEX,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS_RESPONSE:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportType]: [...action.reports],
        },
      };
    case GET_REPORT_BY_PARAMERETS_RESPONSE:
      return {
        ...state,
        selectedReport: action.selectedReport,
      };
    case GET_REPORTS_BY_PARAMERETS_REQUEST:
      return {
        ...state,
        selectedReport: {},
      };
    case GET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
        activeIndex: action.activeIndex,
      };
    default:
      return state;
  }
};
