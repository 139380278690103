export const NEXT = 'smartguide/NEXT';
export const PREVIOUS = 'smartguide/PREVIOUS';
export const RESET = 'smartguide/RESET';

export const navigationGoNext = id => ({
  type: NEXT,
  nextId: id,
});

export const navigationGoPrev = () => ({
  type: PREVIOUS,
});

export const navigationReset = () => ({
  type: RESET,
});
