import * as api from '@/api';
export const CONNECT_USER_REQUEST = 'security/CONNECT_USER_REQUEST';
export const CONNECT_USER_RESPONSE_SUCCEEDED = 'security/CONNECT_USER_RESPONSE_SUCCEEDED';
export const CONNECT_USER_RESPONSE_FAILED = 'security/CONNECT_USER_RESPONSE_FAILED';
export const CONNECT_USER_ALREADY_CONNECTED = 'security/CONNECT_USER_ALREADY_CONNECTED';
export const connectUser = (spId, userEmail, role) => async dispatch => {
  dispatch({ type: CONNECT_USER_REQUEST });

  try {
    const response = await api.connectUserToProAccount(spId, userEmail, role);
    if (response.status === 201) dispatch({ type: CONNECT_USER_RESPONSE_SUCCEEDED });
    else dispatch({ type: CONNECT_USER_RESPONSE_FAILED });
  } catch (e) {
    if (e.response.status === 409) {
      dispatch({ type: CONNECT_USER_ALREADY_CONNECTED });
    } else {
      dispatch({ type: CONNECT_USER_RESPONSE_FAILED });
    }
  }
};

export const reconnectUser = inviteId => async dispatch => {
  dispatch({ type: CONNECT_USER_REQUEST });

  try {
    const response = await api.resendPendingProAccountInvite(inviteId);
    dispatch({ type: response.status === 204 ? CONNECT_USER_RESPONSE_SUCCEEDED : CONNECT_USER_RESPONSE_FAILED });
  } catch (e) {
    dispatch({ type: CONNECT_USER_RESPONSE_FAILED });
  }
};

export const DISCONNECT_USER_REQUEST = 'security/DISCONNECT_USER_REQUEST';
export const DISCONNECT_USER_RESPONSE_SUCCEEDED = 'security/DISCONNECT_USER_RESPONSE_SUCCEEDED';
export const DISCONNECT_USER_RESPONSE_FAILED = 'security/DISCONNECT_USER_RESPONSE_FAILED';
export const disconnectUser = (spId, user) => async dispatch => {
  dispatch({ type: DISCONNECT_USER_REQUEST });

  try {
    const response = await api.disconnectUserFromProAccount(spId, user.id);

    if (response.status === 204) dispatch({ type: DISCONNECT_USER_RESPONSE_SUCCEEDED, removedUserId: user.id });
    else dispatch({ type: DISCONNECT_USER_RESPONSE_FAILED });
  } catch {
    dispatch({ type: DISCONNECT_USER_RESPONSE_FAILED });
  }
};

export const GET_USERS_REQUEST = 'security/GET_USERS_REQUEST';
export const GET_USERS_RESPONSE = 'security/GET_USERS_RESPONSE';
export const getProAccountUsers = spId => async dispatch => {
  dispatch({ type: GET_USERS_REQUEST });

  try {
    const response = await api.getProAccountUsers(spId);

    if (response.status === 200) dispatch({ type: GET_USERS_RESPONSE, users: response.data });
    else dispatch({ type: GET_USERS_RESPONSE, users: [] });
  } catch {
    dispatch({ type: GET_USERS_RESPONSE, users: [] });
  }
};

export const PATCH_USER_SECURITY_LEVEL_REQUEST = 'security/PATCH_USER_SECURITY_LEVEL_REQUEST';
export const PATCH_USER_SECURITY_LEVEL_RESPONSE_SUCCEEDED = 'security/PATCH_USER_SECURITY_LEVEL_RESPONSE_SUCCEEDED';
export const PATCH_USER_SECURITY_LEVEL_RESPONSE_FAILED = 'security/PATCH_USER_SECURITY_LEVEL_RESPONSE_FAILED';
export const changeUserSecurityLevel =
  (spId, userId, role, emailNotifications, pushNotifications) => async dispatch => {
    dispatch({ type: PATCH_USER_SECURITY_LEVEL_REQUEST });

    try {
      const response = await api.updateProAccountRole(spId, userId, role, emailNotifications, pushNotifications);

      if (response.status === 204) dispatch({ type: PATCH_USER_SECURITY_LEVEL_RESPONSE_SUCCEEDED, userId, role });
      else dispatch({ type: PATCH_USER_SECURITY_LEVEL_RESPONSE_FAILED });
    } catch {
      dispatch({ type: PATCH_USER_SECURITY_LEVEL_RESPONSE_FAILED });
    }
  };

export const GET_PENDING_PRO_ACCOUNT_REQUEST = 'security/GET_PENDING_PRO_ACCOUNT_REQUEST';
export const GET_PENDING_PRO_ACCOUNT_RESPONSE = 'security/GET_PENDING_PRO_ACCOUNT_RESPONSE';
export const GET_PENDING_PRO_ACCOUNT_RESPONSE_FAILED = 'security/GET_PENDING_PRO_ACCOUNT_RESPONSE_FAILED';
export const getPendingProAccountUsers = () => async dispatch => {
  dispatch({ type: GET_PENDING_PRO_ACCOUNT_REQUEST });

  try {
    const response = await api.getPendingProAccountUsers();
    if (response.status === 200) {
      dispatch({ type: GET_PENDING_PRO_ACCOUNT_RESPONSE, pendingInvites: response.data });
    } else {
      dispatch({ type: GET_PENDING_PRO_ACCOUNT_RESPONSE_FAILED, pendingInvites: response });
    }
  } catch {
    dispatch({ type: GET_PENDING_PRO_ACCOUNT_RESPONSE_FAILED, error: [] });
  }
};

export const DELETE_PENDING_PRO_ACCOUNT_INVITE = 'security/DELETE_PENDING_PRO_ACCOUNT_INVITE';
export const DELETE_PENDING_PRO_ACCOUNT_INVITE_SUCCEEDED = 'security/DELETE_PENDING_PRO_ACCOUNT_INVITE_SUCCEEDED';
export const DELETE_PENDING_PRO_ACCOUNT_INVITE_FAILED = 'security/DELETE_PENDING_PRO_ACCOUNT_INVITE_FAILED';
export const deletePendingProAccountInvite = invite => async dispatch => {
  dispatch({ type: DELETE_PENDING_PRO_ACCOUNT_INVITE });

  try {
    const response = await api.deletePendingProAccountInvite(invite.id);

    if (response.status === 204) dispatch({ type: DELETE_PENDING_PRO_ACCOUNT_INVITE_SUCCEEDED, removedId: invite.id });
    else dispatch({ type: DELETE_PENDING_PRO_ACCOUNT_INVITE_FAILED });
  } catch {
    dispatch({ type: DELETE_PENDING_PRO_ACCOUNT_INVITE_FAILED });
  }
};

export const PATCH_PENDING_PRO_ACCOUNT_ROLE_REQUEST = 'security/PATCH_PENDING_PRO_ACCOUNT_ROLE_REQUEST';
export const PATCH_PENDING_PRO_ACCOUNT_ROLE_RESPONSE_SUCCEEDED =
  'security/PATCH_PENDING_PRO_ACCOUNT_ROLE_RESPONSE_SUCCEEDED';
export const PATCH_PENDING_PRO_ACCOUNT_ROLE_RESPONSE_FAILED = 'security/PATCH_PENDING_PRO_ACCOUNT_ROLE_RESPONSE_FAILED';
export const changePendingServicePartnerRole = (inviteId, role) => async dispatch => {
  dispatch({ type: PATCH_PENDING_PRO_ACCOUNT_ROLE_REQUEST });

  try {
    const response = await api.updatePendingProAccountRole(inviteId, role);

    if (response.status === 204) dispatch({ type: PATCH_PENDING_PRO_ACCOUNT_ROLE_RESPONSE_SUCCEEDED, inviteId, role });
    else dispatch({ type: PATCH_PENDING_PRO_ACCOUNT_ROLE_RESPONSE_FAILED });
  } catch {
    dispatch({ type: PATCH_PENDING_PRO_ACCOUNT_ROLE_RESPONSE_FAILED });
  }
};
