import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Link } from '@/components/Link';
import { emitClarityTrackEvent } from '@/components/tracking/Clarity';

import { menuItemIds, menuItemIdToEventName } from '../../../constants';
import StoreLink from '../../StoreLink/StoreLink';

function HamburgerSubMenuItem({ menuItem, redirectTokenFunc, logoutUser }) {
  const [hover, setHover] = useState(false);

  const handleHover = () => setHover(!hover);

  const handlePreNavigation = () => emitClarityTrackEvent(menuItemIdToEventName[menuItem.id]);

  let menuItemContent;

  if (menuItem.disabled) {
    menuItemContent = (
      <div key={menuItem.id} className="hamburgerMenuItem__menuItemLink-disabled">
        <FormattedMessage id={menuItem.id} defaultMessage={menuItem.name} />
      </div>
    );
  }

  if (menuItem.id === menuItemIds.SmartHomeDevices) {
    menuItemContent = (
      <StoreLink
        menuItem={menuItem}
        redirectTokenFunc={redirectTokenFunc}
        className="hamburgerMenuItem__menuItemStoreLink"
      />
    );
  }

  if (menuItem.id === menuItemIds.LogOut) {
    menuItemContent = (
      <div variant="text" className="hamburgerMenuItem__menuItemLink" onClick={logoutUser}>
        <FormattedMessage id={menuItem.id} defaultMessage={menuItem.name} />
      </div>
    );
  }

  return (
    <div
      role="option"
      className="hamburgerMenuItem__menuItem"
      style={{
        color: hover ? 'gray' : '#fafafa',
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {menuItemContent || (
        <Link
          key={menuItem.id}
          to={menuItem.path}
          className="hamburgerMenuItem__menuItemLink"
          preNavigationCallback={handlePreNavigation}
        >
          <FormattedMessage id={menuItem.id} defaultMessage={menuItem.name} />
        </Link>
      )}
    </div>
  );
}

HamburgerSubMenuItem.propTypes = {
  menuItem: PropTypes.object,
  redirectTokenFunc: PropTypes.func,
  logoutUser: PropTypes.func.isRequired,
};

export default HamburgerSubMenuItem;
