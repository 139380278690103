import initialState from './initialState';

import {
  ACTIVATE_TRIAL,
  ACTIVATE_TRIAL_FAILED,
  ACTIVATE_TRIAL_SUCCEEDED,
  ADD_TO_CART,
  ADD_TO_CART_FAILED,
  ADD_TO_CART_SUCCEEDED,
  CLOSE_CREATE_ORDER,
  CREATE_ORDER,
  CREATE_ORDER_FAILED,
  CREATE_ORDER_SUCCEEDED,
  GET_CART,
  GET_CART_FAILED,
  GET_CART_SUCCEEDED,
  GET_ORDER,
  GET_ORDERS,
  GET_ORDERS_FAILED,
  GET_ORDERS_SUCCEEDED,
  GET_ORDER_FAILED,
  GET_ORDER_PAYMENT_PROVIDERS,
  GET_ORDER_PAYMENT_PROVIDERS_FAILED,
  GET_ORDER_PAYMENT_PROVIDERS_SUCCEEDED,
  GET_ORDER_SUCCEEDED,
  GET_PAID_BRANDS,
  GET_PAID_BRANDS_FAILED,
  GET_PAID_BRANDS_SUCCEEDED,
  GET_PAYMENT_PROVIDERS,
  GET_PAYMENT_PROVIDERS_FAILED,
  GET_PAYMENT_PROVIDERS_SUCCEEDED,
  GET_PRODUCTS,
  GET_PRODUCTS_FAILED,
  GET_PRODUCTS_SUCCEEDED,
  GET_USER_NEXT_BILLING_DATE,
  GET_USER_NEXT_BILLING_DATE_FAILED,
  GET_USER_NEXT_BILLING_DATE_SUCCEEDED,
  GET_USER_PAYMENT_PROVIDER_SETTINGS,
  GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED,
  GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED_VAT_NUMBER,
  GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED,
  GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED_VAT_NUMBER,
  GET_USER_PAYMENT_PROVIDER_SETTINGS_VAT_NUMBER,
  INIT_CART,
  INIT_CART_FAILED,
  INIT_CART_SUCCEEDED,
  REMOVE_FROM_CART,
  REMOVE_FROM_CART_FAILED,
  REMOVE_FROM_CART_SUCCEEDED,
  SET_SHOP_ID,
  UNLOAD_ORDER,
} from './actions';

import { LOGOUT_USER } from '@/pages/Login/actions';

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOP_ID:
      return { ...state, shopId: action.shopId };
    case GET_PRODUCTS:
      return { ...state, products: { ...state.products, error: null, loading: true } };
    case GET_PRODUCTS_SUCCEEDED:
      return { ...state, products: { ...state.products, items: action.products, loaded: true, loading: false } };
    case GET_PRODUCTS_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          error: action.error.response.status !== 404 && action.error,
          loaded: false,
          loading: false,
          hasFreeServiceError: action.error.response.status === 404,
        },
      };
    case INIT_CART:
      return { ...state, cart: { ...state.cart, loading: true } };
    case INIT_CART_SUCCEEDED:
      return {
        ...state,
        cart: { ...state.cart, ...action.cart, error: null, init: true, loading: false },
      };
    case INIT_CART_FAILED:
      return {
        ...state,
        cart: { ...state.cart, error: action.error, init: true, loading: false },
      };
    case GET_CART:
      return { ...state, cart: { ...state.cart, loading: true } };
    case GET_CART_SUCCEEDED:
      return { ...state, cart: { ...state.cart, ...action.cart, init: true, loading: false } };
    case GET_CART_FAILED:
      return { ...state, cart: { ...state.cart, error: action.error, loading: false } };
    case GET_PAYMENT_PROVIDERS:
      return {
        ...state,
        paymentProviders: { data: [], loading: true, init: false },
      };
    case GET_PAYMENT_PROVIDERS_SUCCEEDED:
      return {
        ...state,
        paymentProviders: { data: action.paymentProviders, init: true, loading: false },
      };
    case GET_PAYMENT_PROVIDERS_FAILED:
      return {
        ...state,
        paymentProviders: { data: [], init: false, loading: false, error: action.error },
      };
    case GET_ORDER_PAYMENT_PROVIDERS:
      return { ...state, paymentProviders: { data: [], loading: true, init: false } };
    case GET_ORDER_PAYMENT_PROVIDERS_SUCCEEDED:
      return {
        ...state,
        paymentProviders: { data: action.paymentProviders, init: true, loading: false },
      };
    case GET_ORDER_PAYMENT_PROVIDERS_FAILED:
      return { ...state, paymentProviders: { data: [], init: false, loading: false, error: action.error } };
    case GET_USER_PAYMENT_PROVIDER_SETTINGS_VAT_NUMBER:
      return { ...state, vatDetails: { ...state.vatDetails, init: true, loading: true } };
    case GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED_VAT_NUMBER:
      return { ...state, vatDetails: { ...state.vatDetails, init: true, loading: false, ...action.vatDetails } };
    case GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED_VAT_NUMBER:
      return { ...state, vatDetails: { ...state.vatDetails, error: action.error } };

    case GET_USER_NEXT_BILLING_DATE:
      return { ...state, nextBillingDate: { ...state.nextBillingDate, init: true, loaded: false } };
    case GET_USER_NEXT_BILLING_DATE_SUCCEEDED:
      return {
        ...state,
        nextBillingDate: { ...state.nextBillingDate, init: true, loaded: true, data: action.nextBillingDate },
      };
    case GET_USER_NEXT_BILLING_DATE_FAILED:
      return { ...state, nextBillingDate: { ...initialState.nextBillingDate, error: action.error } };

    case GET_USER_PAYMENT_PROVIDER_SETTINGS:
      return { ...state, paymentProviders: { ...state.paymentProviders, loading: true } };
    case GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED:
      return {
        ...state,
        paymentProviders: {
          ...state.paymentProviders,
          data: state.paymentProviders.data.map(pp =>
            pp.id === action.paymentProviderSettings.paymentProviderId
              ? {
                  ...pp,
                  settings: action.paymentProviderSettings,
                }
              : pp,
          ),
          loading: false,
        },
      };
    case GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED:
      return {
        ...state,
        paymentProviders: { data: [], init: false, loading: false, error: action.error },
      };

    case ADD_TO_CART:
      return { ...state, cart: { ...state.cart, loading: true } };
    case ADD_TO_CART_SUCCEEDED:
      return { ...state, cart: { ...state.cart, ...action.cart, loading: false } };
    case ADD_TO_CART_FAILED:
      return { ...state, cart: { ...state.cart, error: action.error, loading: false } };
    case REMOVE_FROM_CART:
      return { ...state, cart: { ...state.cart, loading: true } };
    case REMOVE_FROM_CART_SUCCEEDED:
      return { ...state, cart: { ...state.cart, ...action.cart, loading: false } };
    case REMOVE_FROM_CART_FAILED:
      return { ...state, cart: { ...state.cart, error: action.error, loading: false } };
    case CREATE_ORDER:
      return {
        ...state,
        order: { ...state.order, error: null, loading: true },
      };
    case CREATE_ORDER_SUCCEEDED:
      return {
        ...state,
        order: { ...state.order, data: { ...action.order.order }, loading: false },
      };
    case CREATE_ORDER_FAILED:
      return {
        ...state,
        order: { ...state.order, error: action.error, loading: false },
      };
    case GET_ORDER:
      return { ...state, order: { ...state.order, error: null, loading: true } };
    case GET_ORDER_SUCCEEDED:
      return {
        ...state,
        order: { data: { ...state.order.data, ...action.order }, loading: false },
      };
    case GET_ORDER_FAILED:
      return { ...state, order: { ...state.order, error: action.error, loading: false } };
    case UNLOAD_ORDER:
      return { ...state, order: initialState.order };
    case CLOSE_CREATE_ORDER:
      return { ...state, order: { loading: false, data: null }, cart: { ...initialState.cart } };

    case GET_ORDERS:
      return { ...state, orders: { ...state.orders, loading: true } };
    case GET_ORDERS_SUCCEEDED:
      return { ...state, orders: { data: action.orders, init: true, loading: false } };
    case GET_ORDERS_FAILED:
      return { ...state, orders: { data: [], error: action.error, init: true, loading: false } };
    case ACTIVATE_TRIAL:
      return { ...state, trialSubscription: { init: false, loading: true, failed: false } };
    case ACTIVATE_TRIAL_SUCCEEDED:
      return {
        ...state,
        trialSubscription: { init: true, loading: false, failed: false },
        order: initialState.order,
      };
    case ACTIVATE_TRIAL_FAILED:
      return { ...state, trialSubscription: { init: false, loading: false, failed: true } };
    case LOGOUT_USER:
      return { ...initialState };
    case GET_PAID_BRANDS:
      return {
        ...state,
        stripeSpecificPaidBrands: { error: null, loading: true },
      };
    case GET_PAID_BRANDS_SUCCEEDED:
      return {
        ...state,
        stripeSpecificPaidBrands: { data: action.brandList, loading: false, error: null },
        paidBrands: action.paidBrands,
      };
    case GET_PAID_BRANDS_FAILED:
      return {
        ...state,
        stripeSpecificPaidBrands: { data: [], error: action.error, loading: false },
      };
    default:
      return state;
  }
};
