import { AlarmStatus } from '@/pages/Dashboard/types';

export type Noop = () => void;

export type Alarm = {
  alarmId: number;
  deviceId: string;
  severity: number;
  status: AlarmStatus;
  datetime: number;
  createdDatetime: number;
  headerId: string;
  descriptionId: string;
  header: string;
  description: string;
  equipName: string;
  id: string;
  activeAidMode: ActiveAidMode;
};

export type ResolutionStep = {
  title: string;
  description: string;
  photoRefs: string[];
};

export type ResolutionStepsProps = {
  steps: ResolutionStep[];
};

export type StepProps = {
  step: ResolutionStep;
  position: number;
  onImageClick: () => void;
};

export type AlarmCause = {
  title: string;
  probabilityPercentage: number;
  cause: string;
  action: string;
  estimatedTime: string;
  spareParts: string[];
  documentation: string[];
  resolutionSteps: ResolutionStep[];
};

export enum ActiveAidMode {
  ON = 'on',
  OFF = 'off',
}
