import * as api from '@/api';

export const GET_PRO_ACCOUNT_USER_REQUEST = 'profile/GET_PRO_ACCOUNT_USER_REQUEST';
export const GET_PRO_ACCOUNT_USER_RESPONSE_SUCCEDED = 'profile/GET_PRO_ACCOUNT_USER_RESPONSE_SUCCEDED';
export const GET_PRO_ACCOUNT_USER_FAILED = 'profile/GET_PRO_ACCOUNT_USER_FAILED';
export const UPDATE_SYSTEM_NOTIFICATIONS_REQUEST = 'profile/UPDATE_SYSTEM_NOTIFICATIONS_REQUEST';
export const UPDATE_SYSTEM_NOTIFICATIONS_RESPONSE_SUCCEDED = 'profile/UPDATE_SYSTEM_NOTIFICATIONS_RESPONSE_SUCCEDED';
export const UPDATE_SYSTEM_NOTIFICATIONS_FAILED = 'profile/UPDATE_SYSTEM_NOTIFICATIONS_REQUEST_FAILED';
export const CHANGE_NOTIFICATION_SETTINGS_ACTIVE_TAB = 'profile/CHANGE_NOTIFICATION_SETTINGS_ACTIVE_TAB';
export const UPDATE_PRO_ACCOUNT_INFO_REQUEST = 'profile/UPDATE_PRO_ACCOUNT_INFO_REQUEST';
export const UPDATE_PRO_ACCOUNT_INFO_RESPONSE_SUCCEEDED = 'profile/UPDATE_PRO_ACCOUNT_INFO_RESPONSE_SUCCEEDED';
export const UPDATE_PRO_ACCOUNT_INFO_RESPONSE_FAILED = 'profile/UPDATE_PRO_ACCOUNT_INFO_RESPONSE_FAILED';
export const GET_SYSTEM_DETAILS_FOR_NOTIFICATION_REQUEST = 'profile/GET_SYSTEM_DETAILS_FOR_NOTIFICATION_REQUEST';
export const GET_SYSTEM_DETAILS_FOR_NOTIFICATION_RESPONSE_SUCCEDED =
  'profile/GET_SYSTEM_DETAILS_FOR_NOTIFICATION_RESPONSE_SUCCEDED';
export const GET_SYSTEM_DETAILS_FOR_NOTIFICATION_FAILED = 'profile/GET_SYSTEM_DETAILS_FOR_NOTIFICATION_FAILED';

export const getProAccount = (servicePartnerId, userId) => async dispatch => {
  dispatch({ type: GET_PRO_ACCOUNT_USER_REQUEST });
  try {
    const response = await api.getProAccountUser(servicePartnerId, userId);
    if (response.status === 200) {
      dispatch({ type: GET_PRO_ACCOUNT_USER_RESPONSE_SUCCEDED, servicePartner: response.data });
      return true;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: GET_PRO_ACCOUNT_USER_FAILED, error: err.response.data.description });
      return false;
    }
  }

  dispatch({ type: GET_PRO_ACCOUNT_USER_FAILED, error: 'unknown' });
  return false;
};

export const getSystemDetails = () => async dispatch => {
  dispatch({ type: GET_SYSTEM_DETAILS_FOR_NOTIFICATION_REQUEST });
  try {
    const response = await api.getSystemDetailsForNotifications();
    if (response.status === 200) {
      dispatch({ type: GET_SYSTEM_DETAILS_FOR_NOTIFICATION_RESPONSE_SUCCEDED, systems: response.data });
      return true;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: GET_SYSTEM_DETAILS_FOR_NOTIFICATION_FAILED, error: err.response.data.description });
      return false;
    }
  }

  dispatch({ type: GET_SYSTEM_DETAILS_FOR_NOTIFICATION_FAILED, error: 'unknown' });
  return false;
};

export const updateServicePartner = (emailNotifications, pushNotifications, regularNotifications) => async dispatch => {
  dispatch({ type: UPDATE_PRO_ACCOUNT_INFO_REQUEST });

  try {
    const response = await api.updateProAccountInfo(emailNotifications, pushNotifications, regularNotifications);

    if (response.status === 204) {
      dispatch({
        type: UPDATE_PRO_ACCOUNT_INFO_RESPONSE_SUCCEEDED,
        emailNotifications,
        pushNotifications,
        regularNotifications,
      });
    } else dispatch({ type: UPDATE_PRO_ACCOUNT_INFO_RESPONSE_FAILED });
  } catch {
    dispatch({ type: UPDATE_PRO_ACCOUNT_INFO_RESPONSE_FAILED });
  }
};

export const updateSystemForEmailNotifications = (groupId, notifyByEmail, notifyByPush, systems) => async dispatch => {
  dispatch({ type: UPDATE_SYSTEM_NOTIFICATIONS_REQUEST });
  try {
    const response = await api.updateSystemForNotifications(groupId, notifyByEmail, notifyByPush);
    if (response.status === 200) {
      dispatch({ type: UPDATE_SYSTEM_NOTIFICATIONS_RESPONSE_SUCCEDED, systems: systems });
      return true;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: UPDATE_SYSTEM_NOTIFICATIONS_FAILED, error: err.response.data.description });
      return false;
    }
  }

  dispatch({ type: UPDATE_SYSTEM_NOTIFICATIONS_FAILED, error: 'unknown' });
  return false;
};

export const updateSystemForPushNotifications = (groupId, notifyByEmail, notifyByPush, systems) => async dispatch => {
  dispatch({ type: UPDATE_SYSTEM_NOTIFICATIONS_REQUEST });
  try {
    const response = await api.updateSystemForNotifications(groupId, notifyByEmail, notifyByPush);
    if (response.status === 200) {
      dispatch({ type: UPDATE_SYSTEM_NOTIFICATIONS_RESPONSE_SUCCEDED, systems: systems });
      return true;
    }
  } catch (err) {
    if (err.response) {
      dispatch({ type: UPDATE_SYSTEM_NOTIFICATIONS_FAILED, error: err.response.data.description });
      return false;
    }
  }

  dispatch({ type: UPDATE_SYSTEM_NOTIFICATIONS_FAILED, error: 'unknown' });
  return false;
};

export const onChangeActiveTab = activeTab => dispatch => {
  dispatch({ type: CHANGE_NOTIFICATION_SETTINGS_ACTIVE_TAB, activeTab: activeTab });
};
