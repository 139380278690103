import { brandsList } from '@/config/brands';
import React from 'react';

export const BrandLogo = () => {
  return (
    <div className="brand-list-wrapper">
      {brandsList.map(
        brand =>
          !brand.hide && (
            <a
              className="brand-logo-wrapper"
              tabIndex={0}
              key={brand.brand}
              rel="noopener noreferrer"
              href={brand.companyUrl}
              target="_blank"
            >
              <img className="brand-logo" key={brand.brand} src={brand.url} alt={brand.brand} height="70" width="70" />
            </a>
          ),
      )}
    </div>
  );
};

const WorksWithBrands = () => {
  return (
    <div className="works-with-brands-wrapper">
      <BrandLogo />
    </div>
  );
};

export default WorksWithBrands;
