import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as BootstrapLink } from 'react-router-dom';
import './link.scss';

const externalLinkRegex = /^https?:\/\//;

function isExternalLink(uri) {
  return externalLinkRegex.test(uri);
}

const Link = ({ className, keyFor, to, children }) =>
  // Use anchor tag for external links
  isExternalLink(to) ? (
    <a href={to} target="_blank" rel="noopener noreferrer" className={classNames('link', className)}>
      {children}
    </a>
  ) : (
    <BootstrapLink key={keyFor} to={to} className={classNames('link', className)}>
      {children}
    </BootstrapLink>
  );

Link.propTypes = {
  className: PropTypes.string,
  keyFor: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Link.defaultProps = {
  className: '',
  keyFor: '',
};

export default Link;
