import * as api from '@/api';

export const GET_DEVICE_MENU_REQUEST = 'device-menu/GET_DEVICE_MENU_REQUEST';
export const GET_DEVICE_MENU_RESPONSE = 'device-menu/GET_DEVICE_MENU_RESPONSE';
export const getDeviceMenu = (deviceId, id) => async dispatch => {
  dispatch({ type: GET_DEVICE_MENU_REQUEST });

  try {
    const response = await api.getDeviceMenu(deviceId, id);
    dispatch({ type: GET_DEVICE_MENU_RESPONSE, menu: response.data, deviceId, id });
    return true;
  } catch (error) {
    return false;
  }
};

export const INVOKE_DEVICE_EVENT_REQUEST = 'device-menu/INVOKE_DEVICE_EVENT_REQUEST';
export const INVOKE_DEVICE_EVENT_RESPONSE = 'device-menu/INVOKE_DEVICE_EVENT_RESPONSE';
export const invokeDeviceEvent = (deviceId, eventId) => async dispatch => {
  dispatch({ type: INVOKE_DEVICE_EVENT_REQUEST });

  await api.invokeDeviceEvent(deviceId, eventId);

  dispatch({ type: INVOKE_DEVICE_EVENT_RESPONSE });
};

export const EDIT_MENU_SETTING = 'device-menu/EDIT_MENU_SETTING';
export const editMenuSetting = (deviceId, menuId, parameterId, value, unit) => async dispatch => {
  dispatch({ type: EDIT_MENU_SETTING });
  await api.patchMenuSetting(deviceId, menuId, parameterId, value, unit);
};
