export const CREATE_USER_REQUEST = 'register/CREATE_USER_REQUEST';
export const CREATE_USER_RESPONSE = 'register/CREATE_USER_RESPONSE';
export const CREATE_USER_RESPONSE_ERROR = 'register/CREATE_USER_RESPONSE_ERROR';
import * as api from '@/api';

export const createUser = user => async dispatch => {
  dispatch({ type: CREATE_USER_REQUEST });
  const {
    data: { access_token },
  } = await api.issueAnonymousAuthToken();
  try {
    const newUser = {
      ...user,
      passwordConfirm: null,
      name: null,
      fullName: user.name,
      acceptedAgreement: user.acceptedAgreement,
      acceptedPrivacyPolicy: user.acceptedPrivacyPolicy,
      preferredLanguage: user.preferredLanguage || 'en',
    };
    delete newUser.passwordConfirm;
    delete newUser.name;

    const response = await api.createUser(access_token, newUser);
    dispatch({ type: CREATE_USER_RESPONSE, user: response.data });
    return true;
  } catch (e) {
    if (e.response && e.response.status === 400 && e.response.data.description) {
      dispatch({ type: CREATE_USER_RESPONSE_ERROR, error: e.response.data.description });
      return false;
    }
  }

  dispatch({ type: CREATE_USER_RESPONSE_ERROR, error: 'unknown' });
  return false;
};

export const CHECK_OLD_USER_EMAIL_REQUEST = 'register/CHECK_OLD_USER_EMAIL_REQUEST';
export const CHECK_OLD_USER_EMAIL_RESPONSE_SUCCEEDED = 'register/CHECK_OLD_USER_EMAIL_RESPONSE_SUCCEEDED';
export const CHECK_OLD_USER_EMAIL_RESPONSE_FAILED = 'register/CHECK_OLD_USER_EMAIL_RESPONSE_FAILED';
export const checkOldUserEmail = email => async dispatch => {
  dispatch({ type: CHECK_OLD_USER_EMAIL_REQUEST });
  try {
    await api.checkOldUserEmail(email);
    dispatch({ type: CHECK_OLD_USER_EMAIL_RESPONSE_SUCCEEDED });
    return true;
  } catch (e) {
    dispatch({ type: CHECK_OLD_USER_EMAIL_RESPONSE_FAILED, error: e.response.data.description });
    return false;
  }
};

export const SEND_CONFIRMATION_LINK_REQUEST = 'register/SEND_CONFIRMATION_LINK_REQUEST';
export const SEND_CONFIRMATION_LINK_RESPONSE_SUCCEEDED = 'register/SEND_CONFIRMATION_LINK_RESPONSE_SUCCEEDED';
export const SEND_CONFIRMATION_LINK_RESPONSE_FAILED = 'register/SEND_CONFIRMATION_LINK_RESPONSE_FAILED';
export const sendConfirmationLink = userId => async dispatch => {
  dispatch({ type: SEND_CONFIRMATION_LINK_REQUEST });
  try {
    await api.sendConfirmationLink(userId);
    dispatch({ type: SEND_CONFIRMATION_LINK_RESPONSE_SUCCEEDED });
    return true;
  } catch (e) {
    dispatch({ type: SEND_CONFIRMATION_LINK_RESPONSE_FAILED, error: e.response.data.description });
    return false;
  }
};

export const CHECK_USER_EMAIL_REQUEST = 'register/CHECK_USER_EMAIL_REQUEST';
export const CHECK_USER_EMAIL_RESPONSE_FAILED = 'register/CHECK_USER_EMAIL_RESPONSE_FAILED';
export const checkUserEmail = email => async dispatch => {
  const {
    data: { access_token },
  } = await api.issueAnonymousAuthToken();
  dispatch({ type: CHECK_USER_EMAIL_REQUEST });
  try {
    await api.findUserByEmail(access_token, email);
    dispatch({ type: CREATE_USER_RESPONSE_ERROR, error: 'user_exists' });
    return true;
  } catch (e) {
    dispatch({ type: CHECK_USER_EMAIL_RESPONSE_FAILED, error: e.response.data.description });
    return false;
  }
};

export const VALIDATE_OLD_USER_CREDENTIALS_REQUEST = 'register/VALIDATE_OLD_USER_CREDENTIALS_REQUEST';
export const VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED =
  'register/VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED';
export const VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_FAILED = 'register/VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_FAILED';
export const validateOldUserCredentials = (username, password) => async dispatch => {
  dispatch({ type: VALIDATE_OLD_USER_CREDENTIALS_REQUEST });
  try {
    const response = await api.validateOldUserCredentials(username, password);
    dispatch({ type: VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED, data: response.data });
    return true;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      dispatch({ type: VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_FAILED, error: e.response.data.description });
      return false;
    }
  }

  dispatch({ type: VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_FAILED, error: 'unknown' });
  return false;
};

export const MIGRATE_OLD_USER_REQUEST = 'register/MIGRATE_OLD_USER_REQUEST';
export const MIGRATE_OLD_USER_RESPONSE_SUCCEEDED = 'register/MIGRATE_OLD_USER_RESPONSE_SUCCEEDED';
export const MIGRATE_OLD_USER_RESPONSE_FAILED = 'register/MIGRATE_OLD_USER_RESPONSE_FAILED';
export const migrateOldUser =
  (oldUserId, acceptedTosVersion, acceptedPrivacyPolicyVersion, newPassword) => async dispatch => {
    dispatch({ type: MIGRATE_OLD_USER_REQUEST });
    try {
      await api.migrateOldUser(oldUserId, acceptedTosVersion, acceptedPrivacyPolicyVersion, newPassword);
      dispatch({ type: MIGRATE_OLD_USER_RESPONSE_SUCCEEDED });
      return true;
    } catch (e) {
      if (e.response && e.response.status === 400) {
        dispatch({ type: MIGRATE_OLD_USER_RESPONSE_FAILED, error: e.response.data.description });
        return false;
      }
    }

    dispatch({ type: MIGRATE_OLD_USER_RESPONSE_FAILED, error: 'unknown' });
    return false;
  };

export const SET_UP_AGREEMENTS_REQUEST = 'register/SET_UP_AGREEMENTS_REQUEST';
export const SET_UP_AGREEMENTS_RESPONSE_SUCCEEDED = 'register/SET_UP_AGREEMENTS_RESPONSE_SUCCEEDED';
export const SET_UP_AGREEMENTS_RESPONSE_FAILED = 'register/SET_UP_AGREEMENTS_RESPONSE_FAILED';
export const setUpUserAgreements =
  (username, password, acceptedTosVersion, acceptedPrivacyPolicyVersion) => async dispatch => {
    dispatch({ type: SET_UP_AGREEMENTS_REQUEST });
    try {
      await api.setUpAgreements(username, password, acceptedTosVersion, acceptedPrivacyPolicyVersion);
      dispatch({ type: SET_UP_AGREEMENTS_RESPONSE_SUCCEEDED });
      return true;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data.error === 'invalid_grant') {
        dispatch({ type: SET_UP_AGREEMENTS_RESPONSE_FAILED, error: e.response.data.error_description });
        return false;
      }
    }

    dispatch({ type: SET_UP_AGREEMENTS_RESPONSE_FAILED, error: 'unknown' });
    return false;
  };
