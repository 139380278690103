import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { clarity as clarityService } from 'react-microsoft-clarity';

import events, { eventEmitter } from '@/events';

export * from './constants';

export const emitClarityTrackEvent = event => {
  eventEmitter.emit(events.clarity.TRACK, event);
};

const devEnv = process.env.NODE_ENV === 'development';

// TODO: Transfer to hook
function Clarity({ children }) {
  const triggerEvent = ({ namespace, event }) => {
    // eslint-disable-next-line no-undef
    if (!clarity || !clarityService?.hasStarted()) return;
    // eslint-disable-next-line no-console
    if (devEnv) console.log('Clarity event: ', event);

    // eslint-disable-next-line no-undef
    clarity('set', namespace, event);
  };

  useEffect(() => {
    clarityService.init(process.env.CLARITY_KEY);
    // eslint-disable-next-line no-console
    if (devEnv && clarityService?.hasStarted()) console.log('Clarity setup!');

    // TODO: Attach event listeners and handle events
    eventEmitter.on(events.clarity.TRACK, triggerEvent);

    return () => {
      eventEmitter.off(events.clarity.TRACK, triggerEvent);
    };
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
}

Clarity.propTypes = {
  children: PropTypes.node,
};

export default Clarity;
