import { Brands } from '@/config/brands';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.CTA,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#CC0033',
    secondary: '#0D0D0D',
  },
};

export default theme;
