import * as api from '@/api';
import * as langStorageManager from '@/api/language';
import * as staticResources from '@/api/staticResources';

import * as authStorage from '@/utils/localStorage/authentication';
import { setLastUsedSystemId } from '@/utils/localStorage/lastUsedSystem';

import events, { eventEmitter } from '@/events';
import { splitVatNumber } from '@/pages/EditServicePartner/utils';
import { logoutUser } from '@/pages/Login/actions';

export const USER_AUTH_STATUS_CHANGED = 'app/USER_AUTH_STATUS_CHANGED';

export const initApp = () => {
  const tokens = authStorage.getAuthTokens();
  const url = {
    // TODO: Refactor ASAP!
    WEB_URL: api.WEB_URL,
    WEB_PRO_URL: api.WEB_PRO_URL,
    WEB_DEV_URL: api.WEB_DEV_URL,
  };

  return { type: USER_AUTH_STATUS_CHANGED, loggedIn: tokens !== null, url };
};

export const SET_USER_INFO_SUBSCRIPTION = 'app/SET_USER_INFO_SUBSCRIPTION';
export const GET_USER_INFO_REQUEST = 'app/GET_USER_INFO_REQUEST';
export const GET_USER_INFO_RESPONSE = 'app/GET_USER_INFO_RESPONSE';
export const getUserInfo = () => async dispatch => {
  dispatch({ type: GET_USER_INFO_REQUEST });

  try {
    const response = await api.getUserInfo();
    if (response.status === 200) {
      dispatch({ type: GET_USER_INFO_RESPONSE, userInfo: response.data });
    }

    return response.data;
  } catch (e) {
    if (!e.response) {
      // TODO: Log error to AppIns
      console.warn('[Error Log]: getUserInfo: Response property on error object is missing: ', e);
    }

    if (e.response?.status === 404) {
      dispatch(logoutUser());
    }
  }
};

export const SET_PRO_ACCOUNT = 'app/SET_PRO_ACCOUNT';
export const setProAccount = servicePartner => ({ type: SET_PRO_ACCOUNT, servicePartner });

export const GET_PRO_ACCOUNT_REQUEST = 'app/GET_PRO_ACCOUNT_REQUEST';
export const getProAccount = spId => async dispatch => {
  dispatch({ type: GET_PRO_ACCOUNT_REQUEST });

  const response = await api.getProAccountInfo(spId);
  if (response.status === 200) {
    const servicePartner = splitVatNumber(response.data);

    // TODO: refactor this to app initializer
    eventEmitter.emit(events.theme.SET, servicePartner.brands?.[0].brandId.toLowerCase());

    dispatch({ type: SET_PRO_ACCOUNT, servicePartner });
  }
};

export const SELECT_SYSTEM = 'app/SELECT_SYSTEM';
export const selectSystem = system => {
  if (system) {
    setLastUsedSystemId(system.id);
  }
  return { type: SELECT_SYSTEM, system };
};

export const RENAME_SYSTEM_REQUEST = 'app/RENAME_SYSTEM_REQUEST';
export const renameSystem = (system, name) => ({ type: RENAME_SYSTEM_REQUEST, system, name });

export const GET_USER_SYSTEMS_REQUEST = 'app/GET_USER_SYSTEMS_REQUEST';
export const GET_USER_SYSTEMS_RESPONSE = 'app/GET_USER_SYSTEMS_RESPONSE';
export const getUserSystems = lastUsedSystemId => async dispatch => {
  dispatch({ type: GET_USER_SYSTEMS_REQUEST });

  const response = await api.getUserSystems();
  if (response.status === 200) {
    const systems = response.data?.groups;
    dispatch({ type: GET_USER_SYSTEMS_RESPONSE, systems: systems });

    const selectedSystem = lastUsedSystemId && systems.find(system => system.id === lastUsedSystemId);
    dispatch(selectSystem(selectedSystem));
    return selectedSystem; // TODO: Demistify ASAP
  }
};

export const GET_COUNTRIES_REQUEST = 'app/GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_RESPONSE = 'app/GET_COUNTRIES_RESPONSE';
export const getCountries = () => async dispatch => {
  dispatch({ type: GET_COUNTRIES_REQUEST });

  const res = await api.getCountriesList();

  dispatch({ type: GET_COUNTRIES_RESPONSE, countries: res.data });
};

export const GET_LATEST_AGREEMENTS_VERSIONS_REQUEST = 'app/GET_LATEST_AGREEMENTS_VERSIONS_REQUEST';
export const GET_LATEST_AGREEMENTS_VERSIONS_RESPONSE = 'app/GET_LATEST_AGREEMENTS_VERSIONS_RESPONSE';
export const getLatestAgreementsVersions = () => async dispatch => {
  dispatch({ type: GET_LATEST_AGREEMENTS_VERSIONS_REQUEST });

  try {
    const res = await api.getLatestAgreementsVersionsList();
    const agreements = res.data;
    dispatch({ type: GET_LATEST_AGREEMENTS_VERSIONS_RESPONSE, agreements: agreements });
  } catch (error) {
    // If remote API is not responding, query local resources.
    // This call should not fail under any circumstances.
    // If it does, the problem is bigger than this try-catch statement.
    const agreements = await staticResources.getLocalLatestAgreementsVersionsList();
    dispatch({ type: GET_LATEST_AGREEMENTS_VERSIONS_RESPONSE, agreements: agreements });
  }
};

export const GET_CLOUD_STATUS_REQUEST = 'app/GET_CLOUD_STATUS_REQUEST';
export const GET_CLOUD_STATUS_RESPONSE = 'app/GET_CLOUD_STATUS_RESPONSE';
export const getCloudStatus = () => async dispatch => {
  dispatch({ type: GET_CLOUD_STATUS_REQUEST });

  const res = await api.getCloudStatus();

  dispatch({ type: GET_CLOUD_STATUS_RESPONSE, status: res.data });
};

export const GET_ALL_BRANDS_REQUEST = 'app/GET_ALL_BRANDS_REQUEST';
export const GET_ALL_BRANDS_FAILED = 'app/GET_ALL_BRANDS_FAILED';
export const GET_ALL_BRANDS_RESPONSE = 'app/GET_ALL_BRANDS_RESPONSE';
export const getAllBrands = () => async dispatch => {
  dispatch({ type: GET_ALL_BRANDS_REQUEST });

  try {
    const res = await api.getAllBrands();

    dispatch({ type: GET_ALL_BRANDS_RESPONSE, brands: res.data });
  } catch (error) {
    dispatch({ type: GET_ALL_BRANDS_FAILED });
  }
};

export const GET_LAST_USED_LANGUAGE_REQUEST = 'app/GET_LAST_USED_LANGUAGE_REQUEST';
export const GET_LAST_USED_LANGUAGE_RESPONSE = 'app/GET_LAST_USED_LANGUAGE_RESPONSE';
export const getLastUsedLanguage = () => dispatch => {
  dispatch({ type: GET_LAST_USED_LANGUAGE_REQUEST });

  const language = langStorageManager.get();

  dispatch({ type: GET_LAST_USED_LANGUAGE_RESPONSE, lastUsedLanguage: language });

  return language;
};

export const SET_APP_LOADED_STATUS = 'app/SET_APP_LOADED_STATUS';
export const setAppLoadedStatus = status => ({ type: SET_APP_LOADED_STATUS, status });

export const SET_APP_LOADING_STATUS = 'app/SET_APP_LOADING_STATUS';
export const setAppLoadingStatus = status => ({ type: SET_APP_LOADING_STATUS, status });

export const SET_USER_DATA_STATE = 'app/SET_USER_DATA_STATE';
export const setUserDataState = state => ({ type: SET_USER_DATA_STATE, state });

export const SET_LANGUAGES = 'language/SET_LANGUAGES';
export const setSupportedLanguages = languages => ({ type: SET_LANGUAGES, languages });

export const GET_SUPPORTED_LANGUAGES_REQUEST = 'language/GET_SUPPORTED_LANGUAGES_REQUEST';
export const getSupportedLanguages = () => async dispatch => {
  dispatch({ type: GET_SUPPORTED_LANGUAGES_REQUEST });

  const response = await api.getSupportedLanguages();
  if (response.data.length > 0) {
    dispatch(setSupportedLanguages(response.data));
  }
};

export const GET_ALL_LANGUAGES_REQUEST = 'language/GET_ALL_LANGUAGES_REQUEST';
export const getAllLanguages = () => async dispatch => {
  dispatch({ type: GET_ALL_LANGUAGES_REQUEST });

  const response = await api.getAllLanguages();

  if (response.data.length > 0) {
    dispatch(setSupportedLanguages(response.data));
  }
};
