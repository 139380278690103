import {
  ACTIVE_ALARMS_STATUS_RESPONSE,
  ADD_SYSTEM_TAG_RESPONSE,
  CLEAR_SYSTEM_INFORMATION_RESPONSE,
  DELETE_SYSTEM_TAG_RESPONSE,
  DISCONNECT_SYSTEM_RESPONSE,
  GET_CUSTOMER_INFORMATION_REQUEST,
  GET_CUSTOMER_INFORMATION_RESPONSE,
  GET_SYSTEM_INFORMATION_REQUEST,
  GET_SYSTEM_INFORMATION_RESPONSE,
  GET_SYSTEM_STATUS_REQUEST,
  GET_SYSTEM_STATUS_RESPONSE,
  RENAME_SYSTEM_REQUEST_FAILED,
  RENAME_SYSTEM_RESPONSE_SUCCEEDED,
  RESET_SYSTEM_FORM_ERROR_REQUEST,
  UPDATE_SYSTEM_TAG_RESPONSE,
} from './actions';

import { LOGOUT_USER } from '../../../Login/actions';

const initialState = {
  isStatusLoaded: false,
  isStatusLoading: false,
  isSystemInfoLoading: false,
  isCustomerInfoLoading: false,
  systemError: null,
  alarm: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SYSTEM_STATUS_REQUEST:
      return { ...state, isStatusLoading: true };
    case GET_SYSTEM_STATUS_RESPONSE:
      return { ...state, isStatusLoading: false, isStatusLoaded: true, system: action.system };
    case GET_SYSTEM_INFORMATION_REQUEST:
      return { ...state, isSystemInfoLoading: true };
    case GET_SYSTEM_INFORMATION_RESPONSE:
      return {
        ...state,
        isSystemInfoLoading: false,
        categories: action.response.categories,
        points: action.response.points,
        parameters: action.response.parameters,
        tags: action.response.tags,
      };
    case GET_CUSTOMER_INFORMATION_REQUEST:
      return { ...state, isCustomerInfoLoading: true };
    case GET_CUSTOMER_INFORMATION_RESPONSE:
      return { ...state, customer: action.customer, isCustomerInfoLoading: false };
    case CLEAR_SYSTEM_INFORMATION_RESPONSE:
      return {
        ...state,
        isStatusLoaded: false,
        isStatusLoading: false,
        system: null,
        categories: null,
        points: null,
        parameters: null,
        tags: null,
        customer: null,
        alarm: false,
      };
    case ADD_SYSTEM_TAG_RESPONSE:
      return { ...state, tags: [...state.tags, action.result.tag] };
    case DELETE_SYSTEM_TAG_RESPONSE:
      return { ...state, tags: state.tags.filter(a => a !== action.result.tag) };
    case UPDATE_SYSTEM_TAG_RESPONSE:
      return { ...state, tags: [...action.tags] };
    case DISCONNECT_SYSTEM_RESPONSE:
    case LOGOUT_USER:
      return { ...initialState };
    case RENAME_SYSTEM_RESPONSE_SUCCEEDED:
      return { ...state, systemError: null };
    case RENAME_SYSTEM_REQUEST_FAILED:
      return { ...state, systemError: action.error };
    case RESET_SYSTEM_FORM_ERROR_REQUEST:
      return { ...state, systemError: null };
    case ACTIVE_ALARMS_STATUS_RESPONSE:
      return { ...state, alarm: action.alarm };
    default:
      return state;
  }
};
