import { GET_DEVICE_MENU_RESPONSE } from './actions';

const initialState = {};

const saveDeviceMenu = (state, deviceId, menuId, menu) => {
  if (state[deviceId]) {
    return { ...state, [deviceId]: { ...state[deviceId], [menuId]: menu } };
  }
  return { ...state, [deviceId]: { [menuId]: menu } };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICE_MENU_RESPONSE:
      return saveDeviceMenu(state, action.deviceId, action.id, action.menu);
    default:
      return state;
  }
};
