import events, { eventEmitter } from '@/events';
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    eventEmitter.emit(events.appInsights.logError, { error, info });
  }

  handleReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            paddingTop: '30vh',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            margin: 'auto',
            width: '65%',
          }}
        >
          <h1>Ooops! Something went wrong...</h1>
          <h3>
            Please try{' '}
            <a href="#" onClick={this.handleReload}>
              reloading
            </a>{' '}
            the page or <a href="/contact">contact</a> support.
          </h3>
        </div>
      );
    }

    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default ErrorBoundary;
