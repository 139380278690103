import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Button } from 'jpi-cloud-web-ui-kit';

import Link from '@/components/inputs/Link';

import appStoreBadge from '@/assets/img/app-store-badge.png';
import googlePlayBadge from '@/assets/img/google-play-badge.png';

import './footer.scss';

const guestLinks = lang => ({
  common: [
    {
      href: '/legal/faq',
      id: 'footer.faq',
      title: 'FAQ',
      isVisible: true,
    },
  ],
  info: [
    {
      href: '/legal/about',
      id: 'footer.about',
      title: 'About',
      isVisible: true,
    },
    {
      href: '/legal/works-with',
      id: 'slide.title.works-with',
      title: 'Works With',
      isVisible: true,
    },
    {
      href: `/legal/privacy-policy/${lang}`,
      id: 'footer.privacyPolicy',
      title: 'Privacy policy',
      isVisible: true,
    },
    {
      href: `/legal/terms-of-service/${lang}`,
      id: 'footer.termsOfService',
      title: 'Terms of service',
      isVisible: true,
    },
  ],
});

const userLinks = isAdmin => ({
  common: [
    {
      href: '/faq',
      id: 'footer.faq',
      title: 'FAQ',
      isVisible: true,
    },
    {
      href: '/contact',
      id: 'footer.contact',
      title: 'Contact',
      isVisible: true,
    },
    {
      href: '/cookies',
      id: 'footer.cookies',
      title: 'Cookies',
      isVisible: true,
    },
  ],
  info: [
    {
      href: '/about',
      id: 'footer.about',
      title: 'About',
      isVisible: true,
    },
    {
      href: '/works-with',
      id: 'slide.title.works-with',
      title: 'Works With',
      isVisible: true,
    },
    {
      href: '/privacy-policy',
      id: 'footer.privacyPolicy',
      title: 'Privacy policy',
      isVisible: true,
    },
    {
      href: '/terms-of-service',
      id: 'footer.termsOfService',
      title: 'Terms of service',
      isVisible: isAdmin,
    },
  ],
});

// TODO: Check if languageSelected is required
const Footer = ({ isPremiumButtonVisible, isAdmin, subscription, isWelcome = false, languageSelected = '' }) => {
  const history = useHistory();

  const menuLinks = isWelcome ? guestLinks(languageSelected) : userLinks(isAdmin);

  const storePage = history.location.pathname === '/store/subscriptions';

  const isActivateTrialVisible = !subscription;

  return (
    <footer className="footer-container">
      {isPremiumButtonVisible && !storePage && (
        <div className="footer-premium row">
          <Button size="large" color="primary" onClick={() => history.push('/store/subscriptions')}>
            {isActivateTrialVisible ? (
              <FormattedMessage id="button.activateFreeTrial" defaultMessage="Activate Free Trial" />
            ) : (
              <FormattedMessage id="button.getPremium" defaultMessage="Get Premium" />
            )}
          </Button>
        </div>
      )}

      <div className="row">
        <div className="footer-links">
          <div className="footer-links-item">
            <div className="footer-link-text">
              <FormattedMessage id="footer.quick-links.short" defaultMessage="About application" />
            </div>
            {menuLinks.info.map(link => {
              return (
                link.isVisible && (
                  <div key={link.id} className="footer-link">
                    <Link keyFor={link.href + link.title} to={link.href}>
                      <FormattedMessage id={link.id} defaultMessage={link.title} />
                    </Link>
                  </div>
                )
              );
            })}
          </div>
          <div className="footer-links-item">
            <div className="footer-link-text">
              <FormattedMessage id="footer.support" defaultMessage="Support" />
            </div>
            {menuLinks.common.map(link => {
              return (
                link.isVisible && (
                  <div key={link.id} className="footer-link">
                    <Link keyfor={link.href + link.title} to={link.href}>
                      <FormattedMessage id={link.id} defaultMessage={link.title} />
                    </Link>
                  </div>
                )
              );
            })}
          </div>
        </div>
        <div className="footer-block">
          <div className="footer-text">
            <div className="download-heading">
              <FormattedMessage id="footer.download-app.heading" defaultMessage="Download myUplink app" />
            </div>
            <FormattedMessage
              id="footer.download-app"
              defaultMessage="Download the app and keep track of your system wherever you are."
            />
          </div>
          <div className="footer-badges">
            <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/app/id1466746198">
              <img src={appStoreBadge} alt="App store link" className="app-store-badge" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.myuplink.pro"
            >
              <img src={googlePlayBadge} className="google-play-badge" alt="Get it on Google Play" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright row">
        <span>Copyright © {moment().year()} myUpTech AB</span>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  subscription: PropTypes.object,
  languageSelected: PropTypes.string,
  isPremiumButtonVisible: PropTypes.bool,
  isWelcome: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default Footer;
