import React from 'react';
import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';

import Root from './Root';

import { requestInterceptor, responseInterceptor } from './api/requestInterceptor';
import { history } from './store/configureStore';

import store from './store';

import './styles/styles.scss';

require('./assets/img/favicon.ico'); // webpack load favicon.ico

const container = document.getElementById('app');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript

// FIXME: ONLY store requests are intercepted
requestInterceptor(store);
responseInterceptor(store);

const isDev = false; // TODO: Review and use ENV variable to detect

// Accessibility error logging to browser console
if (isDev) {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

// TODO: Refactor and use createRoot after redux upgrade
// https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
// root.render(<Root store={store} history={history} />);
ReactDOM.render(<Root store={store} history={history} />, container);
