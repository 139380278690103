import { applyMiddleware, compose, createStore } from 'redux';

import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';

import createHistory from 'history/createBrowserHistory';
import { createRootReducers } from './combinedReducers';
export const history = createHistory();

function configureStoreProd(initialState) {
  const middlewares = [thunk];

  return createStore(createRootReducers(), initialState, compose(applyMiddleware(...middlewares)));
}

// TODO: Remove DEV store
function configureStoreDev(initialState) {
  const middlewares = [
    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),
    thunk,
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(createRootReducers(), initialState, composeEnhancers(applyMiddleware(...middlewares)));

  if (module.hot) {
    module.hot.accept('./combinedReducers', () => {
      const createNewRootReducers = require('./combinedReducers').createRootReducers;
      store.replaceReducer(createNewRootReducers());
    });
  }

  return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
