import { Brands } from '@/config/brands';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.CONTURA,
  TYPO: {
    ...mainTheme.TYPO,
    font: {
      ...mainTheme.TYPO.font,
      // family: 'Montserrat', // TODO: Check
    },
  },
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#EA7125',
    secondary: '#EA7125',
  },
};

export default theme;
