import * as api from '@/api';

export const GET_ADDRESS_REQUEST = 'profile/GET_ADDRESS_REQUEST';
export const GET_ADDRESS_RESPONSE_SUCCEDED = 'profile/GET_ADDRESS_RESPONSE_SUCCEDED';
export const GET_ADDRESS_NOT_FOUND = 'profile/GET_ADDRESS_NOT_FOUND';
export const GET_ADDRESS_FAILED = 'profile/GET_ADDRESS_FAILED';

export const getAddressInfo = addressId => async dispatch => {
  dispatch({ type: GET_ADDRESS_REQUEST });
  try {
    const response = await api.getAddressInfo(addressId);
    if (response.status === 200) {
      dispatch({ type: GET_ADDRESS_RESPONSE_SUCCEDED, address: response.data });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 404) {
      dispatch({ type: GET_ADDRESS_NOT_FOUND });
    }

    throw e;
  }

  dispatch({ type: GET_ADDRESS_FAILED });
  return false;
};

export const CHANGE_ADDRESS_REQUEST = 'profile/CHANGE_ADDRESS_REQUEST';
export const CHANGE_ADDRESS_RESPONSE_SUCCEDED = 'profile/CHANGE_ADDRESS_RESPONSE_SUCCEDED';
export const CHANGE_ADDRESS_FAILED = 'profile/CHANGE_ADDRESS_FAILED';

export const changeAddressInfo =
  (address, isErrorHandledInternally = true) =>
  async dispatch => {
    dispatch({ type: CHANGE_ADDRESS_REQUEST });
    try {
      const response = await api.changeAddressInfo(address);
      if (response.status == 200 || response.status === 204) {
        dispatch({ type: CHANGE_ADDRESS_RESPONSE_SUCCEDED, address: response.data });
        return true;
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        dispatch({ type: CHANGE_ADDRESS_FAILED, error: e.response.data.description });
        if (isErrorHandledInternally) return false;
        throw e;
      }
    }

    dispatch({ type: CHANGE_ADDRESS_FAILED, error: 'unknown' });
    return false;
  };

export const CREATE_ADDRESS_REQUEST = 'profile/CREATE_ADDRESS_REQUEST';
export const CREATE_ADDRESS_RESPONSE_SUCCEDED = 'profile/CREATE_ADDRESS_RESPONSE_SUCCEDED';
export const CREATE_ADDRESS_FAILED = 'profile/CREATE_ADDRESS_FAILED';

export const createAddressInfo =
  (addressId, address, isErrorHandledInternally = true) =>
  async dispatch => {
    const newAddress = { ...address, id: addressId };
    dispatch({ type: CREATE_ADDRESS_REQUEST });
    try {
      const response = await api.createAddressInfo(newAddress);
      if (response.status === 201 || response.status === 200) {
        dispatch({ type: CREATE_ADDRESS_RESPONSE_SUCCEDED, address: address });
        return true;
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        dispatch({ type: CREATE_ADDRESS_FAILED, error: e.response.data.error_description });
        if (isErrorHandledInternally) return false;
        throw e;
      }
    }

    dispatch({ type: CREATE_ADDRESS_FAILED, error: 'unknown' });
    return false;
  };

export const CREATE_NEW_ADDRESS_ID_REQUEST = 'profile/CREATE_NEW_ADDRESS_ID_REQUEST';
export const CREATE_NEW_ADDRESS_ID_RESPONSE_SUCCEDED = 'profile/CREATE_NEW_ADDRESS_ID_RESPONSE_SUCCEDED';

export const createNewAddressIdForProfile = (userId, addressId) => async dispatch => {
  dispatch({ type: CREATE_NEW_ADDRESS_ID_REQUEST });
  try {
    const userProfileResponse = await api.getUserProfile(userId);
    const newUserInfo = Object.assign({}, userProfileResponse.data);
    newUserInfo.addressId = addressId;
    const response = await api.updateUserProfile(newUserInfo.userId, newUserInfo);
    if (response.status === 200 || response.status === 204) {
      dispatch({ type: CREATE_NEW_ADDRESS_ID_RESPONSE_SUCCEDED, userProfile: newUserInfo });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 404) {
      dispatch({ type: CREATE_ADDRESS_FAILED, error: e.response.data.description });
      return false;
    }
  }

  dispatch({ type: CREATE_ADDRESS_FAILED, error: 'unknown' });
  return false;
};

export const RESET_ADDRESS_FORM_ERROR_REQUEST = 'profile/RESET_ADDRESS_FORM_ERROR_REQUEST';
export const resetAddressError = () => dispatch => dispatch({ type: RESET_ADDRESS_FORM_ERROR_REQUEST });
