export enum StoreTabs {
  Subscriptions = 'subscriptions',
  PaymentSettings = 'payment-settings',
  OrderHistory = 'order-history',
  ShoppingCart = 'shopping-cart',
}

// TODO: Rename and split Subscription status from Order status
export enum SubscriptionOrderStatus {
  Active = 'active',
  Cancelled = 'canceled', // FIXME: typo on BE
  Trial = 'trial',
  Pending = 'pending',
  Failed = 'failed', // Maybe => expired
  Paid = 'paid',
}
