export const splitVatNumber = servicePartner => {
  const servicePartnerWithPrefix = { ...servicePartner };

  if (servicePartnerWithPrefix.address && servicePartnerWithPrefix.address.country) {
    const vatPrefixLength = 2;
    servicePartnerWithPrefix.vatPrefix =
      (servicePartnerWithPrefix.vatNumber && servicePartnerWithPrefix.vatNumber.substring(0, vatPrefixLength)) || '';
    servicePartnerWithPrefix.vatNumber =
      (servicePartnerWithPrefix.vatNumber && servicePartnerWithPrefix.vatNumber.substring(vatPrefixLength)) || '';
  } else {
    servicePartnerWithPrefix.vatPrefix = '';
  }
  return servicePartnerWithPrefix;
};
