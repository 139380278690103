import React, { Component } from 'react';

import MobileMenuButton from './components/MobileMenuButton';

import HamburgerSubMenu from './components/HamburgerSubMenu';

import PropTypes from 'prop-types';
import { menuItemIds } from '../../constants';
import './hamburger-menu.scss';

// TODO: Refactor to func and apply useRef hook
class HamburgerMenu extends Component {
  state = {
    menuOpen: false,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleMenuClick() {
    if (!this.state.menuOpen) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState({ menuOpen: !this.state.menuOpen });
  }

  handleOutsideClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    document.removeEventListener('click', this.handleOutsideClick, false);
    this.setState({ menuOpen: false });
  };

  render() {
    const { menuItems = [], selectedSystem, logoutUser } = this.props;
    const { menuOpen } = this.state;

    // TODO: Memoize
    const orderedMenuItems = menuItems?.reduce(
      ({ items, langItem }, link) => {
        const foundLang = link.id === menuItemIds.Language;

        return {
          items: foundLang ? items : [...items, link],
          langItem: foundLang ? link : langItem,
        };
      },
      {
        items: [],
        langItem: null,
      },
    );

    const sortedItems = [...orderedMenuItems.items, orderedMenuItems.langItem].filter(Boolean);

    const subMenus = sortedItems.map((item, index) => (
      <HamburgerSubMenu key={index} {...item} selectedSystem={selectedSystem} logoutUser={logoutUser}>
        {item.children}
      </HamburgerSubMenu>
    ));

    return (
      <div
        ref={node => {
          this.node = node;
        }}
      >
        <MobileMenuButton open={menuOpen} onClick={this.handleMenuClick.bind(this)} />
        {menuOpen && (
          <div role="menu" aria-expanded={menuOpen} aria-label="Mobile Menu" className="hamburgerMenu">
            <div className="hamburgerMenu__list">{subMenus}</div>
          </div>
        )}
      </div>
    );
  }
}

HamburgerMenu.propTypes = {
  menuItems: PropTypes.array,
  logoutUser: PropTypes.func.isRequired,
  selectedSystem: PropTypes.object,
};

export default HamburgerMenu;
