import { email } from '../../constants';
import {
  CHANGE_NOTIFICATION_SETTINGS_ACTIVE_TAB,
  GET_PRO_ACCOUNT_USER_REQUEST,
  GET_PRO_ACCOUNT_USER_RESPONSE_SUCCEDED,
  GET_SYSTEM_DETAILS_FOR_NOTIFICATION_FAILED,
  GET_SYSTEM_DETAILS_FOR_NOTIFICATION_REQUEST,
  GET_SYSTEM_DETAILS_FOR_NOTIFICATION_RESPONSE_SUCCEDED,
  UPDATE_PRO_ACCOUNT_INFO_RESPONSE_SUCCEEDED,
  UPDATE_SYSTEM_NOTIFICATIONS_FAILED,
  UPDATE_SYSTEM_NOTIFICATIONS_REQUEST,
  UPDATE_SYSTEM_NOTIFICATIONS_RESPONSE_SUCCEDED,
} from './actions';

const initialState = {
  activeTab: email,
  isSystemUpdating: false,
  error: '',
  errorOccured: false,
  isDisabledAllUpdating: false,
  servicePartner: null,
  systems: {
    data: [],
    error: null,
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRO_ACCOUNT_USER_REQUEST:
      return { ...state, isSystemUpdating: true };
    case GET_PRO_ACCOUNT_USER_RESPONSE_SUCCEDED:
      return { ...state, isSystemUpdating: false, servicePartner: action.servicePartner };
    case GET_SYSTEM_DETAILS_FOR_NOTIFICATION_REQUEST:
      return { ...state, loading: true };
    case GET_SYSTEM_DETAILS_FOR_NOTIFICATION_RESPONSE_SUCCEDED:
      return { ...state, loading: false, systems: { ...state.systems, data: action.systems } };
    case GET_SYSTEM_DETAILS_FOR_NOTIFICATION_FAILED:
      return { ...state, loading: false, errorOccured: true, error: action.error };
    case UPDATE_SYSTEM_NOTIFICATIONS_REQUEST:
      return { ...state, isSystemUpdating: true };
    case UPDATE_SYSTEM_NOTIFICATIONS_RESPONSE_SUCCEDED:
      return { ...state, systems: { ...state.systems, data: action.systems }, isSystemUpdating: false };
    case UPDATE_SYSTEM_NOTIFICATIONS_FAILED:
      return { ...state, isSystemUpdating: false, errorOccured: true, error: action.error };
    case CHANGE_NOTIFICATION_SETTINGS_ACTIVE_TAB:
      return { ...state, activeTab: action.activeTab };
    case UPDATE_PRO_ACCOUNT_INFO_RESPONSE_SUCCEEDED:
      return {
        ...state,
        servicePartner: {
          ...state.servicePartner,
          emailNotifications: action.emailNotifications,
          pushNotifications: action.pushNotifications,
          regularNotifications: action.regularNotifications,
        },
      };
    default:
      return state;
  }
};
