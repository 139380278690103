// TODO: Transfer to enum roles and refactor usages
export const SP_ROLES = {
  viewer: 'Viewer',
  editor: 'Editor',
  manager: 'Manager',
  buyer: 'Buyer',
  admin: 'Admin',
};

export const ROLES = {
  ...SP_ROLES,
  // Roles present ONLY on FE
  subscribed: 'Subscribed', // has valid trial or paid (unpaused) subscription
  candidate: 'Candidate', // not approved by the brand company
  guest: 'Guest', // no service partner created
};
