import { RECOVERY_LINK_NO_SUCH_EMAIL, RECOVERY_LINK_SEND_FAILED, RECOVERY_LINK_SENT } from './actions';

export const EmailSendingStatus = {
  INITIAL: 'INITIAL',
  NO_SUCH_EMAIL: 'NO_SUCH_EMAIL',
  UNAVAILABLE: 'UNAVAILABLE',
};

const initialState = {
  emailSendStatus: EmailSendingStatus.INITIAL,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECOVERY_LINK_SENT:
      return { ...state, emailSendStatus: EmailSendingStatus.INITIAL };
    case RECOVERY_LINK_NO_SUCH_EMAIL:
      return { ...state, emailSendStatus: EmailSendingStatus.NO_SUCH_EMAIL };
    case RECOVERY_LINK_SEND_FAILED:
      return { ...state, emailSendStatus: EmailSendingStatus.UNAVAILABLE };
    default:
      return state;
  }
};
