import React, { ReactElement, lazy } from 'react';

import { Redirect } from 'react-router-dom';

import SignedInLayout from '@/components/layout/SignInLayout';
import SignedOutLayout from '@/components/layout/SignOutLayout';

import { PROD_REGISTRATION_BRANDS, PROD_REGISTRATION_COUNTRIES } from '@/config/common';
import { ROLES, SP_ROLES } from '@/config/user';

import { isProductionEnv } from '@/api';
import AuthGuard from './guards/AuthGuard';
import UnAuthGuard from './guards/UnAuthGuard';

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ '@/pages/Dashboard'));
const ProductRegistration = lazy(
  () => import(/* webpackChunkName: "ProductRegistration" */ '@/pages/ProductRegistration'),
);

const NoSubscription = lazy(() => import(/* webpackChunkName: "NoSubscription" */ '@/pages/NoSubscription'));
const SystemGroupsList = lazy(() => import(/* webpackChunkName: "SystemGroupsList" */ '@/pages/SystemGroupsList'));

const NewPassword = lazy(() => import(/* webpackChunkName: "NewPassword" */ '@/pages/NewPassword'));
const ForgotPassword = lazy(() => import(/* webpackChunkName: "ForgotPassword" */ '@/pages/ForgotPassword'));
const Register = lazy(() => import(/* webpackChunkName: "Register" */ '@/pages/Register'));

const SelectOrganization = lazy(() => import(/* webpackChunkName: "Login" */ '@/pages/Login/pages/SelectOrganization'));
const Login = lazy(() => import(/* webpackChunkName: "Login" */ '@/pages/Login/pages/Login'));

// import Home from "./Home";
const FaqPage = lazy(() => import(/* webpackChunkName: "Faq" */ '@/pages/Faq'));
const CookiesPage = lazy(() => import(/* webpackChunkName: "Cookies" */ '@/pages/Cookies'));
const UpdateDeviceFirmware = lazy(
  () => import(/* webpackChunkName: "UpdateDeviceFirmware" */ '@/pages/UpdateDeviceFirmware'),
);
const ContactPage = lazy(() => import(/* webpackChunkName: "Contact" */ '@/pages/Contact'));
const SmartGuidePage = lazy(() => import(/* webpackChunkName: "SmartGuide" */ '@/pages/SmartGuide'));
const ProfilePage = lazy(() => import(/* webpackChunkName: "ProfilePage" */ '@/pages/ProfilePage'));
const Reports = lazy(() => import(/* webpackChunkName: "Reports" */ '@/pages/Reports'));
const ReportInfo = lazy(() => import(/* webpackChunkName: "Reports" */ '@/pages/Reports/components/ReportInfo'));
const NoSystems = lazy(() => import(/* webpackChunkName: "Systems" */ '@/pages/Systems/NoSystems'));
const SystemSettings = lazy(() => import(/* webpackChunkName: "SystemSettings" */ '@/pages/SystemSettings'));
const Invitations = lazy(() => import(/* webpackChunkName: "Invitations" */ '@/pages/Invitations'));
const AddInvitation = lazy(() => import(/* webpackChunkName: "AddInvitation" */ '@/pages/AddInvitation'));
const NewServicePartner = lazy(() => import(/* webpackChunkName: "NewServicePartner" */ '@/pages/NewServicePartner'));
const EditServicePartner = lazy(
  () => import(/* webpackChunkName: "EditServicePartner" */ '@/pages/EditServicePartner'),
);
const SendConfirmEmailLink = lazy(
  () => import(/* webpackChunkName: "SendConfirmEmailLink" */ '@/pages/SendConfirmEmailLink'),
);
const DeleteConfirmation = lazy(
  () => import(/* webpackChunkName: "DeleteConfirmation" */ '@/pages/DeleteConfirmation'),
);
const LeaveConfirmation = lazy(() => import(/* webpackChunkName: "LeaveConfirmation" */ '@/pages/LeaveConfirmation'));
const DeleteProAccountConfirmation = lazy(
  () => import(/* webpackChunkName: "DeleteProAccountConfirmation" */ '@/pages/DeleteProAccountConfirmation'),
);
const ConfirmEmail = lazy(() => import(/* webpackChunkName: "ConfirmEmail" */ '@/pages/ConfirmEmail'));
// const OrderCancel = lazy(() => import(/* webpackChunkName: "OrderCancel" */ '@/pages/OrderCancel'));
const ConfirmEmailUpdate = lazy(
  () => import(/* webpackChunkName: "ConfirmEmailUpdate" */ '@/pages/ConfirmEmailUpdate'),
);

const PrivacyPolicyLegalPage = lazy(
  () => import(/* webpackChunkName: "PrivacyPolicyLegalPage" */ '@/pages/LegalPages/PrivacyPolicyLegalPage'),
);
const TermsOfServiceLegalPage = lazy(
  () => import(/* webpackChunkName: "TermsOfServiceLegalPage" */ '@/pages/LegalPages/TermsOfServiceLegalPage'),
);

const ProTermsOfServiceLegalPage = lazy(
  () => import(/* webpackChunkName: "ProTermsOfServiceLegalPage" */ '@/pages/LegalPages/ProTermsOfServiceLegalPage'),
);

const ProTermsOfServicePage = lazy(
  () => import(/* webpackChunkName: "ProTermsOfService" */ '@/pages/ProTermsOfService'),
);
const TermsOfServicePage = lazy(() => import(/* webpackChunkName: "TermsOfService" */ '@/pages/TermsOfService'));
const PrivacyPolicyPage = lazy(() => import(/* webpackChunkName: "PrivacyPolicy" */ '@/pages/PrivacyPolicy'));

const WorksWith = lazy(() => import(/* webpackChunkName: "WorksWith" */ '@/pages/WorksWith'));
const AboutPage = lazy(() => import(/* webpackChunkName: "About" */ '@/pages/About'));
const Store = lazy(() => import(/* webpackChunkName: "Store" */ '@/pages/Store'));

const RedeemVoucher = lazy(() => import(/* webpackChunkName: "RedeemVoucher" */ '@/pages/RedeemVoucher'));

export const AnonymousGuard = (props): ReactElement => <>{props.children}</>;
export const DefaultLayout = (props): ReactElement => <SignedOutLayout isNotAuthPage {...props} />;

const ALL_ROLES = Object.values(ROLES);
const ALL_SP_ROLES = Object.values(SP_ROLES);

const routes = {
  anonymousRoutes: [
    {
      path: '/login',
      page: Login,
      layout: SignedOutLayout,
      guard: UnAuthGuard,
    },
    {
      path: '/sign-up',
      page: Login,
      layout: SignedOutLayout,
      guard: UnAuthGuard,
    },
    {
      path: '/forgot-password',
      layout: SignedOutLayout,
      page: ForgotPassword,
      guard: UnAuthGuard,
    },
    {
      path: '/password-reset',
      page: NewPassword,
      layout: SignedOutLayout, // TODO: Check this layout
      guard: UnAuthGuard,
    },
    {
      path: '/register',
      layout: SignedOutLayout,
      page: Register,
      guard: UnAuthGuard,
    },
    {
      path: '/confirmation-link',
      layout: SignedOutLayout,
      page: SendConfirmEmailLink,
      guard: UnAuthGuard,
    },
    {
      path: '/delete/confirmation',
      layout: SignedOutLayout,
      page: DeleteConfirmation,
      guard: AnonymousGuard,
    },
    {
      path: '/pro-account/leave/confirmation',
      layout: SignedOutLayout,
      page: LeaveConfirmation,
      guard: AnonymousGuard,
    },
    {
      path: '/pro-account/delete/confirmation',
      layout: SignedOutLayout,
      page: DeleteProAccountConfirmation,
      guard: AnonymousGuard,
    },
    {
      path: '/confirm-email/:token',
      layout: SignedOutLayout,
      page: ConfirmEmail,
      guard: UnAuthGuard,
    },
    {
      path: '/confirm-email-update',
      page: ConfirmEmailUpdate,
      layout: SignedOutLayout,
      guard: AnonymousGuard,
    },
    {
      path: '/legal/privacy-policy/:locale?',
      layout: DefaultLayout,
      page: PrivacyPolicyLegalPage,
      guard: UnAuthGuard,
    },
    {
      path: '/legal/terms-of-service/:locale?',
      layout: DefaultLayout,
      page: TermsOfServiceLegalPage,
      guard: UnAuthGuard,
    },
    {
      path: '/legal/terms-of-service-step-:step/:locale?',
      layout: DefaultLayout,
      page: ProTermsOfServiceLegalPage,
      guard: UnAuthGuard,
    },
    {
      path: '/legal/works-with',
      layout: DefaultLayout,
      page: WorksWith,
      guard: UnAuthGuard,
    },
    {
      path: '/legal/about',
      layout: DefaultLayout,
      page: AboutPage,
      guard: UnAuthGuard,
    },
    {
      path: '/legal/faq',
      page: FaqPage,
      layout: DefaultLayout,
      guard: UnAuthGuard,
    },
  ],

  restrictedGuestRoutes: [
    {
      path: '/login/select-organization',
      page: SelectOrganization,
      layout: SignedOutLayout,
      requiredRoles: [ROLES.guest],
      guard: AuthGuard, // AnonymousGuard
    },
    {
      path: '/new-service-partner',
      page: NewServicePartner,
      layout: SignedInLayout,
      requiredRoles: [ROLES.guest],
      guard: AuthGuard,
    },
    {
      path: '/profile-settings/:tab?',
      page: ProfilePage,
      layout: SignedInLayout,
      requiredRoles: ALL_ROLES,
      guard: AuthGuard,
    },
    {
      path: '/faq',
      page: FaqPage,
      layout: SignedInLayout,
      requiredRoles: ALL_ROLES,
      guard: AuthGuard,
    },
    {
      path: '/about',
      page: AboutPage,
      layout: SignedInLayout,
      requiredRoles: ALL_ROLES,
      guard: AuthGuard,
    },
    {
      path: '/cookies',
      page: CookiesPage,
      layout: SignedInLayout,
      requiredRoles: ALL_ROLES,
      guard: AuthGuard,
    },
    {
      path: '/contact',
      page: ContactPage,
      layout: SignedInLayout,
      requiredRoles: ALL_ROLES,
      guard: AuthGuard,
    },
    {
      path: '/pro-terms-of-service-step-:step',
      page: ProTermsOfServicePage,
      layout: SignedInLayout,
      requiredRoles: ALL_ROLES,
      guard: AuthGuard,
    },
    {
      path: '/pro-terms-of-service',
      // eslint-disable-next-line react/display-name
      page: () => <Redirect from="/pro-terms-of-service" to="/pro-terms-of-service-step-1" />,
      guard: AuthGuard,
    },
    {
      path: '/terms-of-service',
      page: TermsOfServicePage,
      layout: SignedInLayout,
      guard: AuthGuard,
      requiredRoles: [ROLES.admin],
    },
    {
      path: '/privacy-policy',
      page: PrivacyPolicyPage,
      layout: SignedInLayout,
      requiredRoles: ALL_ROLES,
      guard: AuthGuard,
    },
    {
      path: '/works-with',
      page: WorksWith,
      layout: SignedInLayout,
      requiredRoles: ALL_ROLES,
      guard: AuthGuard,
    },
    {
      path: '/cancellation',
      // eslint-disable-next-line react/display-name
      page: () => <Redirect from="/cancellation" to="/store/subscriptions" />,
      guard: AuthGuard,
    },
  ],

  restrictedRoutes: [
    {
      path: '/update-firmware',
      layout: SignedInLayout,
      page: UpdateDeviceFirmware,
      requiredRoles: ALL_ROLES,
      guard: AuthGuard,
    },
    {
      path: '/reports/:type/:reportDate',
      layout: SignedInLayout,
      page: ReportInfo,
      guard: AuthGuard,
      requiredRoles: ALL_SP_ROLES,
      subscribedUser: true,
    },
    {
      path: '/reports',
      layout: SignedInLayout,
      page: Reports,
      guard: AuthGuard,
      requiredRoles: ALL_SP_ROLES,
      subscribedUser: true,
    },
    {
      path: '/smartguide',
      layout: SignedInLayout,
      page: SmartGuidePage,
      requiredRoles: ALL_SP_ROLES,
      guard: AuthGuard,
    },
    {
      path: '/service-partner-settings/:tab?',
      layout: SignedInLayout,
      page: EditServicePartner,
      requiredRoles: ALL_SP_ROLES,
      guard: AuthGuard,
    },
    {
      path: '/redeem-voucher',
      layout: SignedInLayout,
      page: RedeemVoucher,
      guard: AuthGuard,
      requiredRoles: [ROLES.admin, ROLES.buyer],
      // TODO: Setup brand page selection
      brands: ['nibe', 'climatemaster'],
    },
    {
      path: '/no-subscription',
      layout: SignedInLayout,
      page: NoSubscription,
      requiredRoles: ALL_ROLES,
      guard: AuthGuard,
    },
  ],

  premiumRoutes: [
    {
      path: '/systems',
      layout: SignedInLayout,
      page: Dashboard,
      guard: AuthGuard,
      requiredRoles: ALL_SP_ROLES,
      subscribedUser: true, // NOTE we can also use -> type: ROUTE_TYPES.Premium
    },
    {
      path: '/system-settings/system-:id/:tab?',
      layout: SignedInLayout,
      page: SystemSettings,
      guard: AuthGuard,
      requiredRoles: ALL_SP_ROLES,
      subscribedUser: true,
    },
    // TODO: Refactor and use feature based routing ** START **
    {
      path: '/system-groups/:topLevelGroupId/:groupLevel2Id/:groupLevel3Id/:groupLevel4Id',
      layout: SignedInLayout,
      page: SystemGroupsList,
      guard: AuthGuard,
      requiredRoles: ALL_SP_ROLES,
      subscribedUser: true,
    },
    {
      path: '/system-groups/:topLevelGroupId/:groupLevel2Id/:groupLevel3Id',
      layout: SignedInLayout,
      page: SystemGroupsList,
      guard: AuthGuard,
      requiredRoles: ALL_SP_ROLES,
      subscribedUser: true,
    },
    {
      path: '/system-groups/:topLevelGroupId/:groupLevel2Id',
      layout: SignedInLayout,
      page: SystemGroupsList,
      guard: AuthGuard,
      requiredRoles: ALL_SP_ROLES,
      subscribedUser: true,
    },
    {
      path: '/system-groups/:topLevelGroupId',
      layout: SignedInLayout,
      page: SystemGroupsList,
      guard: AuthGuard,
      requiredRoles: ALL_SP_ROLES,
      subscribedUser: true,
    },
    // TODO: Refactor and use feature based routing ** END **

    {
      path: '/no-systems',
      layout: SignedInLayout,
      page: NoSystems,
      requiredRoles: ALL_SP_ROLES,
      guard: AuthGuard,
    },
    {
      path: '/product-registration',
      layout: SignedInLayout,
      page: ProductRegistration,
      eligibleCountries: PROD_REGISTRATION_COUNTRIES,
      eligibleBrands: PROD_REGISTRATION_BRANDS,
      requiredRoles: ALL_SP_ROLES,
      efficiencyPartner: true,
      subscribedUser: true,
      guard: AuthGuard,
      exact: false, // to enable feature based routes
      isHidden: isProductionEnv,
    },
  ],

  brandedPremiumRoutes: [
    {
      path: '/request-access',
      layout: SignedInLayout,
      page: Invitations,
      guard: AuthGuard,
      requiredRoles: [SP_ROLES.admin, SP_ROLES.manager],
      subscribedUser: true,
    },
    {
      path: '/add-request-access',
      layout: SignedInLayout,
      page: AddInvitation,
      guard: AuthGuard,
      requiredRoles: [SP_ROLES.admin, SP_ROLES.manager],
      subscribedUser: true,
    },
  ],

  store: [
    {
      path: '/store/checkout/:checkoutId', // TODO: Confirm this redirect with business
      // eslint-disable-next-line react/display-name
      page: () => <Redirect from="/store/checkout/:checkoutId" to="/store/shopping-cart?checkout=:checkoutId" />,
      guard: AuthGuard,
    },
    {
      path: '/store/orders/:orderId',
      // eslint-disable-next-line react/display-name
      page: () => <Redirect from="/store/orders/:orderId" to="/store/order-history?order=:orderId" />,
      guard: AuthGuard,
    },
    {
      path: '/store/:tab?',
      layout: SignedInLayout,
      page: Store,
      guard: AuthGuard,
      requiredRoles: [SP_ROLES.admin, SP_ROLES.buyer],
    },
  ],
};

export const allRoutes = [
  ...routes.anonymousRoutes,
  ...routes.restrictedGuestRoutes,
  ...routes.restrictedRoutes,
  ...routes.premiumRoutes,
  ...routes.brandedPremiumRoutes,
  ...routes.store,
];

export default routes;
