import { NEXT, PREVIOUS, RESET } from './actions';

const initialState = {
  currentId: 'L1',
  history: ['L1'],
};

export default (state = initialState, action) => {
  const len = state.history.length;
  switch (action.type) {
    case NEXT:
      return {
        currentId: action.nextId,
        history: [...state.history, action.nextId],
      };
    case PREVIOUS:
      if (len <= 1) {
        return Object.assign({}, initialState);
      }
      return {
        currentId: state.history[len - 2],
        history: state.history.slice(0, len - 1),
      };
    case RESET:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};
