import * as api from '@/api';
import logger from '@/utils/logger';

import { SET_USER_INFO_SUBSCRIPTION } from '../AppInitializer/actions';

export const GET_SP_SUBSCRIPTIONS_REQUEST = 'features/GET_SP_SUBSCRIPTIONS_REQUEST';
export const GET_SP_SUBSCRIPTIONS_RESPONSE = 'features/GET_SP_SUBSCRIPTIONS_RESPONSE';
export const GET_SP_SUBSCRIPTIONS_FAILED = 'features/GET_SP_SUBSCRIPTIONS_FAILED';

// TODO: Move to AppInit actions
export const getSPSubscriptionStatus = () => async dispatch => {
  dispatch({ type: GET_SP_SUBSCRIPTIONS_REQUEST });

  try {
    const res = await api.getProAccountSubscriptionPaymentStatus();

    dispatch({
      type: GET_SP_SUBSCRIPTIONS_RESPONSE,
      isValid: res?.data.isValid,
    });

    dispatch({
      type: SET_USER_INFO_SUBSCRIPTION,
      // TODO: Re-arange payload structure from data to be appripriate for redux
      payload: res?.data,
    });

    return true;
  } catch (e) {
    dispatch({ type: GET_SP_SUBSCRIPTIONS_FAILED });
    logger.toAppInsights(e);

    return false;
  }
};

export const GET_DEVICE_AVAILABLE_FEATURES_REQUEST = 'features/GET_DEVICE_AVAILABLE_FEATURES_REQUEST';
export const GET_DEVICE_AVAILABLE_FEATURES_RESPONSE = 'features/GET_DEVICE_AVAILABLE_FEATURES_RESPONSE';
export const GET_DEVICE_AVAILABLE_FEATURES_FAILED = 'features/GET_DEVICE_AVAILABLE_FEATURES_FAILED';

export const getDeviceFeatures = deviceId => async dispatch => {
  dispatch({ type: GET_DEVICE_AVAILABLE_FEATURES_REQUEST });

  try {
    const res = await api.getDeviceAvailableFeatures(deviceId);
    if (res.status === 200) {
      dispatch({ type: GET_DEVICE_AVAILABLE_FEATURES_RESPONSE, deviceFeatures: res.data });
    } else {
      dispatch({ type: GET_DEVICE_AVAILABLE_FEATURES_FAILED });
    }
  } catch (e) {
    dispatch({ type: GET_DEVICE_AVAILABLE_FEATURES_FAILED });
  }
};
