export const GET_PRODUCTS = 'store/GET_PRODUCTS';
export const GET_PRODUCTS_SUCCEEDED = 'store/GET_PRODUCTS_SUCCEEDED';
export const GET_PRODUCTS_FAILED = 'store/GET_PRODUCTS_FAILED';

export const INIT_CART = 'store/INIT_CART';
export const INIT_CART_SUCCEEDED = 'store/INIT_CART_SUCCEEDED';
export const INIT_CART_FAILED = 'store/INIT_CART_FAILED';

export const GET_PAYMENT_PROVIDERS = 'store/GET_PAYMENT_PROVIDERS';
export const GET_PAYMENT_PROVIDERS_SUCCEEDED = 'store/GET_PAYMENT_PROVIDERS_SUCCEEDED';
export const GET_PAYMENT_PROVIDERS_FAILED = 'store/GET_PAYMENT_PROVIDERS_FAILED';

export const GET_ORDER_PAYMENT_PROVIDERS = 'store/GET_ORDER_PAYMENT_PROVIDERS';
export const GET_ORDER_PAYMENT_PROVIDERS_SUCCEEDED = 'store/GET_ORDER_PAYMENT_PROVIDERS_SUCCEEDED';
export const GET_ORDER_PAYMENT_PROVIDERS_FAILED = 'store/GET_ORDER_PAYMENT_PROVIDERS_FAILED';

export const GET_USER_PAYMENT_PROVIDER_SETTINGS_VAT_NUMBER = 'store/GET_USER_PAYMENT_PROVIDER_SETTINGS_VAT_NUMBER';
export const GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED_VAT_NUMBER =
  'store/GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED_VAT_NUMBER';
export const GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED_VAT_NUMBER =
  'store/GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED_VAT_NUMBER';

export const GET_USER_NEXT_BILLING_DATE = 'store/GET_USER_NEXT_BILLING_DATE';
export const GET_USER_NEXT_BILLING_DATE_SUCCEEDED = 'store/GET_USER_NEXT_BILLING_DATE_SUCCEEDED';
export const GET_USER_NEXT_BILLING_DATE_FAILED = 'store/GET_USER_NEXT_BILLING_DATE_FAILED';

export const GET_USER_PAYMENT_PROVIDER_SETTINGS = 'store/GET_USER_PAYMENT_PROVIDER_SETTINGS';
export const GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED = 'store/GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED';
export const GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED = 'store/GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED';

export const GET_CART = 'store/GET_CART';
export const GET_CART_SUCCEEDED = 'store/GET_CART_SUCCEEDED';
export const GET_CART_FAILED = 'store/GET_CART_FAILED';

export const ADD_TO_CART = 'store/ADD_TO_CART';
export const ADD_TO_CART_SUCCEEDED = 'store/ADD_TO_CART_SUCCEEDED';
export const ADD_TO_CART_FAILED = 'store/ADD_TO_CART_FAILED';

export const REMOVE_FROM_CART = 'store/REMOVE_FROM_CART';
export const REMOVE_FROM_CART_SUCCEEDED = 'store/REMOVE_FROM_CART_SUCCEEDED';
export const REMOVE_FROM_CART_FAILED = 'store/REMOVE_FROM_CART_FAILED';

export const CREATE_ORDER = 'store/CREATE_ORDER';
export const CREATE_ORDER_SUCCEEDED = 'store/CREATE_ORDER_SUCCEEDED';
export const CREATE_ORDER_FAILED = 'store/CREATE_ORDER_FAILED';

export const GET_ORDER = 'store/GET_ORDER';
export const GET_ORDER_SUCCEEDED = 'store/GET_ORDER_SUCCEEDED';
export const GET_ORDER_FAILED = 'store/GET_ORDER_FAILED';
export const CLOSE_CREATE_ORDER = 'store/CLOSE_CREATE_ORDER';
export const UNLOAD_ORDER = 'store/UNLOAD_ORDER';

export const SET_SHOP_ID = 'store/SET_SHOP_ID';

export const GET_ORDERS = 'store/GET_ORDERS';
export const GET_ORDERS_SUCCEEDED = 'store/GET_ORDERS_SUCCEEDED';
export const GET_ORDERS_FAILED = 'store/GET_ORDERS_FAILED';

export const ACTIVATE_TRIAL = 'store/ACTIVATE_TRIAL';
export const ACTIVATE_TRIAL_SUCCEEDED = 'store/ACTIVATE_TRIAL_SUCCEEDED';
export const ACTIVATE_TRIAL_FAILED = 'store/ACTIVATE_TRIAL_FAILED';

export const GET_PAID_BRANDS = 'store/GET_PAID_BRANDS';
export const GET_PAID_BRANDS_SUCCEEDED = 'store/GET_PAID_BRANDS_SUCCEEDED';
export const GET_PAID_BRANDS_FAILED = 'store/GET_PAID_BRANDS_FAILED';

export const setShopId = shopId => ({
  type: SET_SHOP_ID,
  shopId: shopId,
});

export const getProducts = () => ({
  type: GET_PRODUCTS,
});

export const getProductsSucceeded = products => ({
  type: GET_PRODUCTS_SUCCEEDED,
  products: products,
});

export const getProductsFailed = error => ({
  type: GET_PRODUCTS_FAILED,
  error: error,
});

export const initCart = () => ({
  type: INIT_CART,
});

export const initCartSucceeded = cart => ({
  type: INIT_CART_SUCCEEDED,
  cart: cart,
});

export const initCartFailed = error => ({
  type: INIT_CART_FAILED,
  error: error,
});

export const getPaymentProviders = cart => ({
  type: GET_PAYMENT_PROVIDERS,
  cart: cart,
});

export const getPaymentProvidersSucceeded = paymentProviders => ({
  type: GET_PAYMENT_PROVIDERS_SUCCEEDED,
  paymentProviders: paymentProviders,
});

export const getPaymentProvidersFailed = error => ({
  type: GET_PAYMENT_PROVIDERS,
  error: error,
});

export const getUserPaymentProviderSettingsVatNumber = () => ({
  type: GET_USER_PAYMENT_PROVIDER_SETTINGS_VAT_NUMBER,
});

export const getUserPaymentProviderSettingsSucceededVatNumber = vatDetails => ({
  type: GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED_VAT_NUMBER,
  vatDetails: vatDetails,
});

export const getUserPaymentProviderSettingsFailedVatNumber = error => ({
  type: GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED_VAT_NUMBER,
  error: error,
});

export const getUserNextBillingDate = () => ({
  type: GET_USER_NEXT_BILLING_DATE,
});

export const getUserNextBillingDateSucceeded = nextBillingDate => ({
  type: GET_USER_NEXT_BILLING_DATE_SUCCEEDED,
  nextBillingDate: nextBillingDate,
});

export const getUserNextBillingDateFailed = error => ({
  type: GET_USER_NEXT_BILLING_DATE_FAILED,
  error: error,
});

export const getUserPaymentProviderSettings = user => ({
  type: GET_USER_PAYMENT_PROVIDER_SETTINGS,
  user: user,
});

export const getUserPaymentProviderSettingsSucceeded = paymentProviderSettings => ({
  type: GET_USER_PAYMENT_PROVIDER_SETTINGS_SUCCEEDED,
  paymentProviderSettings,
});

export const getUserPaymentProviderSettingsFailed = error => ({
  type: GET_USER_PAYMENT_PROVIDER_SETTINGS_FAILED,
  error: error,
});

export const getCart = () => ({
  type: GET_CART,
});

export const getCartSucceeded = cart => ({
  type: GET_CART_SUCCEEDED,
  cart: cart,
});

export const getCartFailed = error => ({
  type: GET_CART_FAILED,
  error: error,
});

export const addToCart = (cart, product, variant) => ({
  type: ADD_TO_CART,
  cart: cart,
  product: product,
  variant: variant,
});

export const addToCartSucceeded = cart => ({
  type: ADD_TO_CART_SUCCEEDED,
  cart: cart,
});

export const addToCartFailed = error => ({
  type: ADD_TO_CART_FAILED,
  error: error,
});

export const removeFromCart = (cart, cartItem) => ({
  type: REMOVE_FROM_CART,
  cart: cart,
  cartItem: cartItem,
});

export const removeFromCartSucceeded = cart => ({
  type: REMOVE_FROM_CART_SUCCEEDED,
  cart: cart,
});

export const removeFromCartFailed = error => ({
  type: REMOVE_FROM_CART_FAILED,
  error: error,
});

export const createOrder = () => ({
  type: CREATE_ORDER,
});

export const createOrderSucceeded = order => ({
  type: CREATE_ORDER_SUCCEEDED,
  order: order,
});

export const createOrderFailed = error => ({
  type: CREATE_ORDER_FAILED,
  error: error,
});

export const getOrder = id => ({
  type: GET_ORDER,
  id: id,
});

export const getOrderSucceeded = order => ({
  type: GET_ORDER_SUCCEEDED,
  order: order,
});

export const getOrderFailed = error => ({
  type: GET_ORDER_FAILED,
  error: error,
});

export const closeCreatedOrder = () => ({
  type: CLOSE_CREATE_ORDER,
});

export const unloadOrder = () => ({
  type: UNLOAD_ORDER,
});

export const getOrders = () => ({
  type: GET_ORDERS,
});

export const getOrdersSucceeded = orders => ({
  type: GET_ORDERS_SUCCEEDED,
  orders: orders,
});

export const getOrdersFailed = error => ({
  type: GET_ORDERS_FAILED,
  error: error,
});

export const activateTrial = () => ({
  type: ACTIVATE_TRIAL,
});

export const activateTrialSucceeded = () => ({
  type: ACTIVATE_TRIAL_SUCCEEDED,
});

export const activateTrialFailed = error => ({
  type: ACTIVATE_TRIAL_FAILED,
  error: error,
});

// TODO: Refactor and rename action
export const getPaidBrands = () => ({
  type: GET_PAID_BRANDS,
});

export const paidBrandsSucceeded = (brandList, paidBrands) => ({
  type: GET_PAID_BRANDS_SUCCEEDED,
  brandList: brandList,
  paidBrands: paidBrands,
});

export const paidBrandsFailed = error => ({
  type: GET_PAID_BRANDS_FAILED,
  error: error,
});
