import * as api from '../api';

export const GET_TEXT_REQUEST = 'text-db/GET_TEXT_REQUEST';
export const GET_TEXT_RESPONSE = 'text-db/GET_TEXT_RESPONSE';
export const getText = (ids, langId) => async (dispatch, getState) => {
  dispatch({ type: GET_TEXT_REQUEST });
  const { textDb } = getState();

  const existingTranslations = ids.map(id => textDb[id]).filter(t => !!t);
  const missingTranslationsIds = ids.filter(id => !textDb[id]);

  if (missingTranslationsIds.length === 0) {
    dispatch({ type: GET_TEXT_RESPONSE, translations: existingTranslations });

    return existingTranslations;
  }

  const missingTranslations = await api.getText(missingTranslationsIds, langId);
  const translations = [...missingTranslations, ...existingTranslations];

  dispatch({ type: GET_TEXT_RESPONSE, translations: translations });
  return translations;
};

export const RELOAD_TRANSLATIONS_REQUEST = 'text-db/RELOAD_TRANSLATIONS_REQUEST';
export const reloadTranslations = language => async (dispatch, getState) => {
  dispatch({ type: RELOAD_TRANSLATIONS_REQUEST });

  // TODO: Potentially unsafe, wrap with try block
  const { textDb } = getState();
  const ids = Object.keys(textDb);

  const translations = await api.getText(ids, language);

  dispatch({ type: GET_TEXT_RESPONSE, translations: translations });
};
