import { Brands } from '@/config/brands';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.JASPI,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#983222',
    secondary: '#983222',
  },
};

export default theme;
