import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './components/App';
import LocalizationWrapper from './localization/LocalizationWrapper';

const Root = ({ store }) => (
  <Provider store={store}>
    <LocalizationWrapper>
      <Router
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}
      >
        <App />
      </Router>
    </LocalizationWrapper>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
