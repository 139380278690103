import {
  CHANGE_USER_PROFILE_FAILED,
  GET_TIME_ZONES_RESPONSE_SUCCEDED,
  GET_USER_PROFILE_NOT_FOUND,
  GET_USER_PROFILE_RESPONSE_SUCCEDED,
  RESET_PROFILE_FORM_ERROR_REQUEST,
} from './actions';

import {
  CHANGE_ADDRESS_FAILED,
  CREATE_NEW_ADDRESS_ID_RESPONSE_SUCCEDED,
  GET_ADDRESS_RESPONSE_SUCCEDED,
} from '../AddressTab/actions';

import { CHANGE_EMAIL_FAILED, CHANGE_EMAIL_RESPONSE_SUCCEDED } from '../../actions';

import { timeZoneOffsetRegex } from '@/config/common';

const initialState = {
  userProfile: {},
  timeZones: null,
  isCreatingNew: false,
  profileError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_RESPONSE_SUCCEDED:
      return { ...state, userProfile: action.userProfile };
    case GET_USER_PROFILE_NOT_FOUND:
      return { ...state, isCreatingNew: true };
    case CHANGE_USER_PROFILE_FAILED:
      return { ...state, profileError: action.error };
    case CREATE_NEW_ADDRESS_ID_RESPONSE_SUCCEDED:
      return { ...state, userProfile: action.userProfile };
    case GET_TIME_ZONES_RESPONSE_SUCCEDED:
      return {
        ...state,
        timeZones: action.timeZones
          .map(function (elm) {
            return {
              id: elm.tzId,
              text: elm.olsonName + ' (' + elm.serializedString.split(';')[2].match(timeZoneOffsetRegex).pop() + ')',
            };
          })
          .sort((a, b) => (a.text > b.text ? 1 : -1)),
      };
    case RESET_PROFILE_FORM_ERROR_REQUEST:
      return { ...state, profileError: null };
    case CHANGE_EMAIL_FAILED:
      return { ...state, profileError: action.error };
    case CHANGE_EMAIL_RESPONSE_SUCCEDED:
      return { ...state, profileError: null };
    case CHANGE_ADDRESS_FAILED:
      return { ...state, profileError: action.error };
    case GET_ADDRESS_RESPONSE_SUCCEDED:
      return { ...state, profileError: null };
    default:
      return state;
  }
};
