import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Footer from '../Footer';
import Header from '../Header'; // rendme to Header

import { BRAND_APPROVAL_STATUSES } from '@/config/common';
import { ROLES } from '@/config/user';

import { APP_STATUS_IDLE } from '../SignOutLayout/SignOutLayout';

import './signed-in-layout.scss';

export const HIDE_GET_PREMIUM_BUTTON = ['/store', '/redeem-voucher', '/dashboard'];

class SignInLayout extends Component {
  componentDidMount() {
    // TODO: Refactor and change logic
    document.body.classList.add('signed-in');
  }

  componentWillUnmount() {
    document.body.classList.remove('signed-in');
  }

  render() {
    const { userInfo, servicePartner, selectedSystem, status, children, ...rest } = this.props;

    const spRoles = userInfo?.spRoles || [];

    const isAdmin = spRoles?.some(role => role === ROLES.admin);
    const isBuyer = spRoles?.some(role => role === ROLES.buyer);
    const isBuyerOrAdmin = isAdmin || isBuyer;

    const userHasBrands = servicePartner?.brands?.length > 0;

    const allBrandsAreApproved =
      userHasBrands && servicePartner.brands.every(brand => brand.approvalStatus === BRAND_APPROVAL_STATUSES.APPROVED);

    const isPremiumButtonVisible =
      isBuyerOrAdmin &&
      allBrandsAreApproved &&
      !userInfo?.recurringPayment?.isValid &&
      !HIDE_GET_PREMIUM_BUTTON.includes(rest.path);

    return (
      <div>
        {/* TODO: Move to AppStatus component and fire notification on status change */}
        {status?.statusCode?.value !== APP_STATUS_IDLE && <div className="status-banner">{status.statusText}</div>}

        <Header />

        <section id="main-content" className={rest.greyBg && 'grey-bg'}>
          <div className="container-fluid">{children}</div>
        </section>

        <Footer
          subscription={userInfo?.recurringPayment?.subscription}
          isPremiumButtonVisible={isPremiumButtonVisible}
          isAdmin={isAdmin}
        />
      </div>
    );
  }
}

SignInLayout.propTypes = {
  userInfo: PropTypes.object,
  servicePartner: PropTypes.object,
  selectedSystem: PropTypes.object,
  children: PropTypes.node,
  status: PropTypes.object,
};

export default connect(({ app: { userInfo, selectedSystem, servicePartner, status, systems } }) => ({
  userInfo,
  systems,
  selectedSystem,
  servicePartner,
  status, // referrs to app status
}))(SignInLayout);
