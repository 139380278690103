import React, { lazy, ReactElement, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BRAND_APPROVAL_STATUSES } from '@/config/common';

import SignedInLayout from '@/components/layout/SignInLayout';
import { IServicePartner, IUserInfo } from '@/pages/Dashboard/types';

const NotAllowed = lazy(() => import(/* webpackChunkName: "NotAllowed" */ '@/pages/NotAllowed'));

type PermissionsGuardProps = {
  children: ReactElement;
  userInfo: IUserInfo;
  servicePartner: IServicePartner;
  subscribedUser?: boolean;
  efficiencyPartner?: boolean;
  eligibleCountries?: string[];
  eligibleBrands?: string[];
  requiredRoles?: string[];
};

export function PermissionsGuard({
  children,
  userInfo,
  servicePartner,
  subscribedUser = false,
  efficiencyPartner = false,
  eligibleCountries = [],
  eligibleBrands = [],
  requiredRoles = [],
}: PermissionsGuardProps) {
  const history = useHistory();
  const [preventAccess, setPreventAccess] = useState(false);

  const subsPayment = userInfo?.recurringPayment;

  const userHasOrHadSubscriptions = () => subsPayment?.subscription !== null;
  const userHasValidPaidSubscription = () => subsPayment?.isValid && !subsPayment?.isPaused;

  const userHasPermissions = () => requiredRoles.some(role => userInfo?.spRoles.includes(role));

  const spHasEligibleCountry = () => {
    if (eligibleCountries.length > 0) return eligibleCountries.includes(servicePartner?.address?.country?.name);

    return true;
  };

  const spHasEligibleBrand = () => {
    if (eligibleBrands.length > 0) {
      const [mainBrand] = servicePartner?.brands || [];
      return mainBrand && eligibleBrands.includes(mainBrand?.brandId?.toLowerCase());
    }

    return true;
  };

  const spIsEfficiencyPartner = () => servicePartner?.isEfficiencyPartner === true;

  const spHasBrandApprovals = () =>
    !servicePartner?.brands?.some(brand => brand.approvalStatus !== BRAND_APPROVAL_STATUSES.APPROVED);

  const applyRestrictions = () => {
    // TODO: Fire INFO notification that user has to get brand approval first
    if (!spHasBrandApprovals()) setPreventAccess(true);

    // TODO: Fire INFO notification that user has to activate trial first
    if (!userHasOrHadSubscriptions()) {
      if (history.location.pathname === '/redeem-voucher') setPreventAccess(true);
    }

    // TODO: Fire INFO notification that user has to buy subscriptions first
    if (subscribedUser && !userHasValidPaidSubscription()) {
      if (history.location.pathname === '/systems') return history.push('/no-subscription');

      setPreventAccess(true);
    }

    // TODO: Fire INFO notification that SP is not efficiencyPartner
    if (efficiencyPartner && !spIsEfficiencyPartner()) setPreventAccess(true);

    // TODO: Fire INFO notification that user has to get permissions first
    if (!userHasPermissions()) setPreventAccess(true);

    // TODO: Fire INFO notification that SP country is not eligible
    if (!spHasEligibleCountry()) setPreventAccess(true);

    // TODO: Fire INFO notification that SP brand is not eligible
    if (!spHasEligibleBrand()) setPreventAccess(true);
  };

  useEffect(() => {
    applyRestrictions();
  }, [userInfo, servicePartner]);

  return (
    <>
      {preventAccess ? (
        <SignedInLayout hideHero={false}>
          <NotAllowed />
        </SignedInLayout>
      ) : (
        children
      )}
    </>
  );
}

const stateProps = ({ app: { userInfo, servicePartner } }) => ({
  userInfo,
  servicePartner,
});

export default connect(stateProps)(PermissionsGuard);
