import React from 'react';

import { NotificationContainer } from 'react-notifications';

import 'react-notifications/lib/notifications.css';

// TODO: Check with business and reimplement if we need to listen server notifications
function Notifications() {
  // const state = {
  //   displayedNotifications: [],
  //   notifications: this.props.notifications || [],
  // };

  // const createNotification = ({ type, title, message }) => {
  //   let messageText = message;
  //   if (message && message.length > 100) {
  //     messageText = message.substring(0, 100) + '...';
  //   }

  //   switch (type) {
  //     case 1:
  //       NotificationManager.error(messageText, title, 5000);
  //       break;
  //     case 2:
  //       NotificationManager.warning(messageText, title, 3000);
  //       break;
  //     case 3:
  //       NotificationManager.info(messageText, title);
  //       break;
  //   }
  // };

  return <NotificationContainer />;
}

export default Notifications;
