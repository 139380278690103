import { Spinner } from 'jpi-cloud-web-ui-kit';
import React, { CSSProperties } from 'react';

import { spinnerDarkColor } from '@/config/common';

export interface LoaderProps {
  dark?: boolean;
  size?: number;
  color?: string;
  isPage?: boolean;
  ariaLabel?: string;
  styles?: CSSProperties;
}

const pageStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
};

const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px 0',
};

const Loader = ({ dark = true, size = 6.8, color, isPage = false, ariaLabel, styles }: LoaderProps) => {
  return (
    <div style={isPage ? pageStyle : { ...style, ...styles }} aria-label={ariaLabel}>
      <Spinner center color={dark ? spinnerDarkColor : color} size={size} />
    </div>
  );
};

// FIXME: Check styled components not applying styles
// const Loader = ({ dark = false }: LoaderProps) => (
//   <CenteredSpinner>
//     <Spinner center color={dark ? spinnerDarkColor : undefined} />
//   </CenteredSpinner>
// );

export default Loader;
