import { emitClarityTrackEvent, headerTrackingEvents } from '@/components/tracking/Clarity';
import { Tooltip } from 'jpi-cloud-web-ui-kit';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../DesktopMenu/desktop-menu.scss';

const StoreLink = ({ menuItem, redirectTokenFunc, disabledDueToBrands, className }) => {
  const handleRedirectToStoreLink = () => {
    if (!disabledDueToBrands) {
      emitClarityTrackEvent(headerTrackingEvents.HEADER_SMART_HOME_DEVICES_NAVIGATE);
      redirectTokenFunc();
    }
  };
  return (
    <Tooltip
      key={menuItem.id}
      overlay={
        disabledDueToBrands && (
          <FormattedMessage
            id={'menu.brand.notapproved.message'}
            defaultMessage="One of your selected brands is awaiting approval."
          />
        )
      }
      position="right"
    >
      <div
        className={
          disabledDueToBrands ? 'desktopSubMenu__link-disabled' : className ? className : 'desktopSubMenu__link'
        }
      >
        <a
          rel="noopener noreferrer"
          target="_blank"
          aria-label={disabledDueToBrands ? 'Disabled Link - Smart Home Devices' : 'Smart Home Devices'}
          className={disabledDueToBrands ? 'desktopSubMenu__iotStoreLink-disabled' : 'desktopSubMenu__iotStoreLink'}
          onClick={handleRedirectToStoreLink}
        >
          <FormattedMessage id={menuItem.id} defaultMessage={menuItem.name} />
        </a>
      </div>
    </Tooltip>
  );
};

StoreLink.propTypes = {
  menuItem: PropTypes.object,
  redirectTokenFunc: PropTypes.func,
  disabledDueToBrands: PropTypes.bool,
  className: PropTypes.string,
};

export default StoreLink;
