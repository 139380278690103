import { getFromLocalStorageByKey, removeFromLocalStorage, saveToLocalStorage } from '.';

export const DASHBOARD_SYSTEMS_ALERT = 'DASHBOARD_SYSTEMS_ALERT';

export type SystemsAlertStatus = {
  userEmail: string;
  createdAt: string;
};

export const getDashAlertStatus = (): null | SystemsAlertStatus => getFromLocalStorageByKey(DASHBOARD_SYSTEMS_ALERT);

export const setDashAlertStatus = (status: SystemsAlertStatus) => saveToLocalStorage(DASHBOARD_SYSTEMS_ALERT, status);

export const clearDashAlertStatus = (): void => removeFromLocalStorage(DASHBOARD_SYSTEMS_ALERT);
