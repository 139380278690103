export enum SystemSettingTab {
  Status = 'status',
  Notifications = 'notifications',
  Menu = 'menu',
  History = 'history',
  Devices = 'devices',
  SystemDiagram = 'system-diagram',
  CustomerInfo = 'customer-info',
  AboutManufacturer = 'about-manufacturer',
  DeviceScheduling = 'device-scheduling',
}

export type AncestorGroup = {
  groupId: string;
  groupName: string;
  parentGroup: AncestorGroup;
};

export type FlattenAncestorsType = {
  path: string;
} & Omit<AncestorGroup, 'parentGroup'>;
