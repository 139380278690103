import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import LanguageSelector from '@/components/layout/LanguageSelector';

import DesktopSubMenu from './DesktopSubMenu';

import '../desktop-menu.scss';

const DesktopMenuItem = ({ id, name, children, align, useIcon, icon, selectedSystem, logoutUser }) => {
  const customStyle = {
    backgroundImage: 'url(' + icon + ')',
    backgroundSize: 'contain',
  };
  const alignClass = align === 'right' ? ' desktopMenu__item--right' : '';

  if (id === 'menu.headers.language')
    return (
      <li id={id} className={'desktopMenu__item--languageSelector desktopMenu__item' + alignClass}>
        <LanguageSelector />
      </li>
    );

  return (
    <li id={id} className={'desktopMenu__item' + alignClass}>
      {!useIcon ? (
        <a className="desktopMenu__itemLink">
          <FormattedMessage id={id} defaultMessage={name} />
          <span className="caret"></span>
        </a>
      ) : (
        <a style={customStyle} className={'desktopMenu__itemLink desktopMenu__itemLink--icon'}></a>
      )}

      <DesktopSubMenu subMenuItems={children} align={align} selectedSystem={selectedSystem} logoutUser={logoutUser} />
    </li>
  );
};

DesktopMenuItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.array,
  align: PropTypes.string,
  useIcon: PropTypes.bool,
  icon: PropTypes.string,
  selectedSystemId: PropTypes.string,
  selectedSystem: PropTypes.object,
  logoutUser: PropTypes.func.isRequired,
};

export default DesktopMenuItem;
