import * as api from '@/api';

export const GET_SYSTEM_FLOW_DIAGRAM_REQUEST = 'systemDiagramTab/GET_SYSTEM_FLOW_DIAGRAM_REQUEST';
export const GET_SYSTEM_FLOW_DIAGRAM_RESPONSE = 'systemDiagramTab/GET_SYSTEM_FLOW_DIAGRAM_RESPONSE';

export const getSystemFlowDiagramInfo = deviceId => async dispatch => {
  dispatch({ type: GET_SYSTEM_FLOW_DIAGRAM_REQUEST });
  const response = await api.getSystemFlowDiagramInfo(deviceId);
  if (response.status === 200) {
    dispatch({ type: GET_SYSTEM_FLOW_DIAGRAM_RESPONSE, systemFlowInfo: response.data });
  }
};

export const GET_SYSTEM_DIAGRAM_PARAMETERS_REQUEST = 'systemDiagramTab/GET_SYSTEM_DIAGRAM_PARAMETERS_REQUEST';
export const GET_SYSTEM_DIAGRAM_PARAMETERS_RESPONSE = 'systemDiagramTab/GET_SYSTEM_DIAGRAM_PARAMETERS_RESPONSE';

export const getSystemParameters = (deviceId, parameterIds) => async dispatch => {
  dispatch({ type: GET_SYSTEM_DIAGRAM_PARAMETERS_REQUEST });
  const response = await api.getDeviceParameterValue(deviceId, parameterIds);
  if (response.status === 200) {
    dispatch({ type: GET_SYSTEM_DIAGRAM_PARAMETERS_RESPONSE, parameters: response.data });
  }
};
