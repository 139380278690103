export const findGroupById = (groups, findId, findCb = null) => {
  for (let gIndex = 0; gIndex < groups.length; gIndex++) {
    const group = groups[gIndex];
    if (group.id === findId) {
      if (findCb) {
        findCb(groups, gIndex);
      }
      return group;
    } else if (group.childGroups && group.childGroups.length) {
      const subGroup = findGroupById(group.childGroups, findId, findCb);
      if (subGroup) {
        return subGroup;
      }
    }
  }

  return null;
};
