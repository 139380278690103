import { EventEmitter } from 'events';

const clarity = {
  TRACK: 'TRACK',
};

const appInsights = {
  track: 'app_insights_track_event',
  pageView: 'app_insights_page_view',
  logError: 'app_insights_log_error',
};

const theme = {
  SET: 'SET_THEME',
};

const inApp = {
  auth: {
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    LOGOUT: 'LOGOUT',
    LOGIN: 'LOGIN',
  },
};

export const eventEmitter = new EventEmitter();

export default {
  clarity,
  appInsights,
  theme,
  inApp,
};
