const lastUsedLanguageKey = 'LAST_USED_LANGUAGE';

export const set = language => {
  localStorage.setItem(lastUsedLanguageKey, language);
};

export const get = () => {
  return localStorage.getItem(lastUsedLanguageKey);
};

export const languageSortOrder = [
  { name: 'cs-CZ', displayName: 'Čeština', sortOrder: 10 },
  { name: 'da-DK', displayName: 'Danish', sortOrder: 20 },
  { name: 'de-DE', displayName: 'Deutsch', sortOrder: 30 },
  { name: 'et-EE', displayName: 'Eesti', sortOrder: 40 },
  { name: 'en-US', displayName: 'English', sortOrder: 50 },
  { name: 'es-Es', displayName: 'Español', sortOrder: 60 },
  { name: 'fr-FR', displayName: 'Français', sortOrder: 70 },
  { name: 'it-IT', displayName: 'Italiano', sortOrder: 80 },
  { name: 'hu-HU', displayName: 'Magyar', sortOrder: 90 },
  { name: 'nl-NL', displayName: 'Nederlands', sortOrder: 100 },
  { name: 'nb-NO', displayName: 'Norsk', sortOrder: 110 },
  { name: 'pl-PL', displayName: 'Polski', sortOrder: 120 },
  { name: 'ro-RO', displayName: 'Română', sortOrder: 130 },
  { name: 'ru-RU', displayName: 'Русский', sortOrder: 140 },
  { name: 'sk-SK', displayName: 'Slovenský', sortOrder: 150 },
  { name: 'sl-SI', displayName: 'Slovenščina', sortOrder: 160 },
  { name: 'fi-FI', displayName: 'Suomi', sortOrder: 170 },
  { name: 'sv-SE', displayName: 'Svenska', sortOrder: 180 },
];
