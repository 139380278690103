import {
  GET_SYSTEM_DIAGRAM_PARAMETERS_REQUEST,
  GET_SYSTEM_DIAGRAM_PARAMETERS_RESPONSE,
  GET_SYSTEM_FLOW_DIAGRAM_REQUEST,
  GET_SYSTEM_FLOW_DIAGRAM_RESPONSE,
} from './actions';

const initialState = {
  isSystemDiagramLoaded: false,
  isSystemDiagramLoading: false,
  systemFlowInfo: {},
  deviceParameters: {
    isParametersLoading: false,
    isParametersLoaded: false,
    parameters: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SYSTEM_FLOW_DIAGRAM_REQUEST:
      return { ...state, isSystemDiagramLoading: true };
    case GET_SYSTEM_FLOW_DIAGRAM_RESPONSE:
      return {
        ...state,
        isSystemDiagramLoading: false,
        isSystemDiagramLoaded: true,
        systemFlowInfo: action.systemFlowInfo,
      };
    case GET_SYSTEM_DIAGRAM_PARAMETERS_REQUEST:
      return { ...state, deviceParameters: { ...state.deviceParameters, isParametersLoading: true } };
    case GET_SYSTEM_DIAGRAM_PARAMETERS_RESPONSE:
      return {
        ...state,
        deviceParameters: {
          ...state.deviceParameters,
          isParametersLoading: false,
          isParametersLoaded: true,
          parameters: action.parameters,
        },
      };
    default:
      return state;
  }
};
