import { Brands } from '@/config/brands';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.CLIMATEMASTER,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#2A6452',
    secondary: '#65C5A8',
  },
};

export default theme;
