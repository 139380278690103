import { CHANGE_SELECTED_TAB } from '../../../actions';
import { SystemSettingTab } from '../../../types';
import { ALARM_STATUSES } from '../config';
import {
  ACTIVATE_AID_MODE_ALARM_REQUEST,
  ACTIVATE_AID_MODE_ALARM_RESPONSE_FAILED,
  ACTIVATE_AID_MODE_ALARM_RESPONSE_SUCCEEDED,
  DESELECT_ALARM,
  GET_ALARMS_REQUEST,
  GET_ALARMS_RESPONSE_SUCCEEDED,
  GET_ALARM_CAUSES_REQUEST,
  GET_ALARM_CAUSES_RESPONSE_SUCCEEDED,
  RESET_ALARM_REQUEST,
  RESET_ALARM_RESPONSE_FAILED,
  RESET_ALARM_RESPONSE_SUCCEEDED,
  SELECT_ALARM,
} from './actions';

const initialState = {
  loading: false,
  alarms: {
    inbox: null,
    archived: null,
  },
  selectedAlarm: null,
  alarmCauses: [],
  resetedAlarms: [],
  alarmsInAidMode: [],
};

const isReseted = (alarm, alarmToReset) =>
  alarm.deviceId === alarmToReset.deviceId && alarm.alarmId === alarmToReset.alarmId;

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALARMS_REQUEST:
      if (state.alarms[action.alarmsType] && state.alarms[action.alarmsType].deviceId !== action.deviceId) {
        return {
          ...state,
          alarms: {
            inbox: null,
            archived: null,
          },
        };
      }
      return { ...state, loading: true };
    case GET_ALARMS_RESPONSE_SUCCEEDED: {
      const alarms = state.alarms[action.alarmsType] ? state.alarms[action.alarmsType].alarms : [];
      return {
        ...state,
        alarms: {
          ...state.alarms,
          [action.alarmsType]: {
            alarms: [...(action.page <= 1 ? [] : alarms), ...action.alarms],
            deviceId: action.deviceId,
            page: action.page,
            totalCount: action.totalCount,
          },
        },
        loading: false,
      };
    }
    case RESET_ALARM_REQUEST:
      return {
        ...state,
        resetedAlarms: [...state.resetedAlarms, action.alarm],
      };
    case RESET_ALARM_RESPONSE_SUCCEEDED: {
      const resetedAlarm = state.alarms.inbox.alarms.find(a => isReseted(a, action.alarm));

      return {
        ...state,
        alarms: {
          // START: This chunk of code is faking the alarm reset
          inbox: {
            ...state.alarms.inbox,
            alarms: state.alarms.inbox.alarms.filter(a => !isReseted(a, action.alarm)),
          },
          archived: {
            ...state.alarms.archived,
            alarms: [{ ...resetedAlarm, status: ALARM_STATUSES.DISMISSED_BY_DEVICE }, ...state.alarms.archived.alarms],
          },
          // END: This chunk of code is faking the alarm reset
        },
        resetedAlarms: state.resetedAlarms.filter(a => !isReseted(a, action.alarm)),
        selectedAlarm: action.alarm.id === state.selectedAlarm?.id ? null : state.selectedAlarm,
      };
    }
    case RESET_ALARM_RESPONSE_FAILED:
      return {
        ...state,
        resetedAlarms: state.resetedAlarms.filter(a => !isReseted(a, action.alarm)),
      };
    case ACTIVATE_AID_MODE_ALARM_REQUEST:
      return {
        ...state,
        alarmsInAidMode: [
          ...state.alarmsInAidMode,
          {
            deviceId: action.alarm.deviceId,
            aidMode: action.aidMode,
          },
        ],
      };
    case ACTIVATE_AID_MODE_ALARM_RESPONSE_SUCCEEDED:
      return {
        ...state,
        alarms: {
          ...state.alarms,
          inbox: {
            ...state.alarms.inbox,
            alarms: state.alarms.inbox.alarms.map(e =>
              e.deviceId === action.request.alarm.deviceId ? { ...e, activeAidMode: action.request.aidMode } : e,
            ),
          },
        },
        selectedAlarm:
          state.selectedAlarm?.deviceId === action.request.alarm.deviceId
            ? { ...state.selectedAlarm, activeAidMode: action.request.aidMode }
            : state.selectedAlarm,
        alarmsInAidMode: state.alarmsInAidMode.filter(a => a.deviceId !== action.request.alarm.deviceId),
      };
    case ACTIVATE_AID_MODE_ALARM_RESPONSE_FAILED:
      return {
        ...state,
        alarmsInAidMode: state.alarmsInAidMode.filter(a => a.deviceId !== action.request.alarm.deviceId),
      };
    case SELECT_ALARM:
      return {
        ...state,
        selectedAlarm: action.alarm,
      };
    case CHANGE_SELECTED_TAB: {
      return action.selectedTab === SystemSettingTab.Notifications
        ? {
            ...state,
            selectedAlarm: null,
          }
        : state;
    }
    case DESELECT_ALARM: {
      return {
        ...state,
        selectedAlarm: null,
      };
    }
    case GET_ALARM_CAUSES_REQUEST: {
      return {
        ...state,
        alarmCauses: [],
      };
    }
    case GET_ALARM_CAUSES_RESPONSE_SUCCEEDED: {
      return {
        ...state,
        alarmCauses: action.alarmCauses,
      };
    }

    default:
      return state;
  }
};
