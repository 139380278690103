import { SET_PRO_ACCOUNT } from '../AppInitializer/actions';
import {
  GET_DEVICE_AVAILABLE_FEATURES_FAILED,
  GET_DEVICE_AVAILABLE_FEATURES_RESPONSE,
  GET_SP_SUBSCRIPTIONS_FAILED,
  GET_SP_SUBSCRIPTIONS_RESPONSE,
} from './actions';
import { menuSystem } from './menuSystem';

import { checkGroupingAvailability } from './utils';

const initialPremiumFeatures = {
  history: false,
  manage: false,
  organization: false,
};

const initialDeviceFeatures = {
  menuSystem: menuSystem.none,
  menu: {
    name: '',
    system: menuSystem.none,
  },
  resetAlarm: false,
  aidMode: false,
  smartGuide: false,
  scheduling: {
    scheduleOverride: false,
    scheduleWeekly: false,
    scheduleVacation: false,
  },
  zones: false,
  boost: {
    hotWater: false,
    ventilation: false,
  },
};

const initialGroupingAvailable = {
  initialGroupingAvailable: true,
};

const initialState = {
  premiumFeatures: initialPremiumFeatures,
  deviceFeatures: initialDeviceFeatures,
  isGroupingAvailable: initialGroupingAvailable,
};

const saveSubscriptions = (state, isValid) => {
  // automatically grant 'history' and 'manage' premium subscriptions for device
  // when Service Partner has a premium SP subscription
  const hasPremiumFeatures = isValid === true;
  const premiumFeatures = {
    history: hasPremiumFeatures,
    manage: hasPremiumFeatures,
    organization: hasPremiumFeatures,
  };

  return {
    ...state,
    premiumFeatures,
  };
};

const saveDeviceFeatures = (state, deviceFeatures) => ({
  ...state,
  deviceFeatures,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SP_SUBSCRIPTIONS_RESPONSE:
      // NOTE: Check if action is used at all here on PRO, may be left-over from Consumer
      return saveSubscriptions(state, action.isValid);
    case GET_SP_SUBSCRIPTIONS_FAILED:
      return saveSubscriptions(state, false);
    case GET_DEVICE_AVAILABLE_FEATURES_RESPONSE:
      return saveDeviceFeatures(state, action.deviceFeatures);
    case GET_DEVICE_AVAILABLE_FEATURES_FAILED:
      return saveDeviceFeatures(state, initialDeviceFeatures);
    case SET_PRO_ACCOUNT:
      return { ...state, isGroupingAvailable: checkGroupingAvailability(action.servicePartner) };
    default:
      return state;
  }
};
