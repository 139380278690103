import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectLanguage } from '@/components/layout/LanguageSelector/actions';
import { sortArrayByLocaleCompare } from '@/utils';

import { languageOptions } from '@/config/languages';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';

function CustomLanguageSelector({ className, selectLanguage, selectedLanguage, showShortcodes, showIcon = true }) {
  const changeLanguage = async e => {
    e.preventDefault();
    const { value } = e.target;

    await selectLanguage(value);
  };

  const customClasses = className || '';
  const sortedLanguageOptions = sortArrayByLocaleCompare(languageOptions, 'label', 'asc');

  return (
    <div className={'customLanguageSelector ' + customClasses}>
      <select
        className="languageSelector__selectControl"
        id="language-selector"
        onChange={e => changeLanguage(e)}
        value={selectedLanguage}
        aria-label="Select language"
      >
        {sortedLanguageOptions.map(({ label, value }) => {
          return (
            <option key={value} value={value}>
              {showShortcodes ? value.toUpperCase() : label}
            </option>
          );
        })}
      </select>
      {showIcon && <FontAwesomeIcon icon="globe-americas" size="2x" id="language-globe" />}
    </div>
  );
}

CustomLanguageSelector.propTypes = {
  className: PropTypes.string,
  selectedLanguage: PropTypes.string,
  selectLanguage: PropTypes.func.isRequired,
  showShortcodes: PropTypes.bool,
  showIcon: PropTypes.bool,
};

const mapStateToProps = ({ language: { selectedLanguage } }) => ({
  selectedLanguage,
});

const mapDispatchToProps = {
  selectLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomLanguageSelector);
