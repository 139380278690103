import events, { eventEmitter } from '@/events';

const toAppInsights = (error: Error) => {
  if (process.env.NODE_ENV !== 'production') return console.error('Logger', { ...error });

  // TODO: Wrap this error for beter readability on the platform
  eventEmitter.emit(events.appInsights.logError, error);
};

export default {
  toAppInsights,
};
