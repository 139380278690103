import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: 'None',
  COLORS: {
    ...mainTheme.COLORS,
  },
};

export default theme;
