import * as api from '@/api';

export const weeklyTab = { id: 'reports.tabs.weekly', defaultMessage: 'Weekly report' };
export const dailyTab = { id: 'reports.tabs.daily', defaultMessage: 'Daily report' };
export const DAY_INDEX = 1;
export const WEEK_INDEX = 2;

export const GET_REPORTS_REQUEST = 'reports/GET_REPORTS_REQUEST';
export const GET_REPORTS_RESPONSE = 'reports/GET_REPORTS_RESPONSE';

export const getReports = type => async dispatch => {
  dispatch({ type: GET_REPORTS_REQUEST });

  const response = await api.getReports(type);
  if (response.status === 200) {
    dispatch({ type: GET_REPORTS_RESPONSE, reportType: type, reports: response.data });
  }
};

export const GET_REPORTS_BY_PARAMERETS_REQUEST = 'reports/GET_REPORTS_BY_PARAMERETS_REQUEST';
export const GET_REPORT_BY_PARAMERETS_RESPONSE = 'reports/GET_REPORTS_BY_PARAMERETS_RESPONSE';

export const getReportByParameters = (type, reportDate) => async dispatch => {
  dispatch({ type: GET_REPORTS_BY_PARAMERETS_REQUEST });

  const response = await api.getReportByParameters(type, reportDate);
  if (response.status === 200) {
    dispatch({ type: GET_REPORT_BY_PARAMERETS_RESPONSE, selectedReport: response.data });
  }
};

export const GET_ACTIVE_TAB = 'reports/GET_ACTIVE_TAB';
export const getActiveTab = (activeTab, activeIndex) => async dispatch =>
  dispatch({ type: GET_ACTIVE_TAB, activeTab: activeTab, activeIndex });
