const LAST_USED_SYSTEM = 'LAST_USED_SYSTEM';

export const setLastUsedSystemId = systemId => {
  localStorage.setItem(LAST_USED_SYSTEM, systemId);
};

export const getLastUsedSystemId = () => {
  return localStorage.getItem(LAST_USED_SYSTEM);
};

export const removeLastUsedSystemId = () => {
  localStorage.removeItem(LAST_USED_SYSTEM);
};
