import React from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

function Link({ to, children, tag: TagName = 'div', preNavigationCallback, ...rest }) {
  const { push } = useHistory();

  const handleClick = e => {
    preNavigationCallback?.();

    if (!to) return e.preventDefault();

    push(to);
  };

  return (
    <TagName role="link" onClick={handleClick} {...rest}>
      {children}
    </TagName>
  );
}

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  preNavigationCallback: PropTypes.func,
  tag: PropTypes.string,
};

export default Link;
